import React from "react"
import Layout from "../../components/Layout/index"
import { useState, useEffect } from 'react'
import AdminService from '../../services/admin/AdminService';
import OwnerTable from '../../components/Tables/owner_table'
import DriverInfo from "../../components/Tables/driver_manage_info"
import Conformation from "../../components/common/conformation"
import CarInfo from "../../components/common/car_info_model"
import OwnerInformation from './ownerInfo'
import { sideBarItems } from '../../utils';
import _ from 'lodash';
import { Pagination } from "../../components/common/pagination";
import OwnerSearch from "../../components/OwnerSearch";

// TODO: Filter Search is caching some items and showing wrong data.

const Owners = ({ history, location }) => {
  
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [showOwnerInfo, setShowOwnerInfo] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [carInfo, setCarInfo] = useState();
  const [isedit, setIsedit] = useState(true);

  //this is for pAGINATION HANDLE 
  const query = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(query.get('page')) || 1);
  const [limit, setLimit] = useState(parseInt(query.get('limit')) || 15);
  const [totalPages, setTotalPages] = useState(1);

  const [showpopu, setShowpopu] = useState(true);

  const updateURLParams = (newPage, newLimit) => {
    const params = new URLSearchParams();
    params.set('page', newPage);
    params.set('limit', newLimit);
    history.replace({ search: params.toString() });
  };

  const showpopuSection = () => {
    setShowpopu(true);

  }

  const handleSearchQuery = (type, value) => {
    switch (type) {
      case 'name':
      case 'mobile':
      case 'hasVehicle':
      case 'hasDriver':
        if (value) {
          setSearchQuery(prev => ({ ...prev, [type]: value }));
        } else {
          setSearchQuery(prev => {
            const newQuery = { ...prev };
            delete newQuery[type];
            return newQuery;
          });
        }
        break;
      default:
        break;
    }
  }

  const clearSearch = () => {
    setSearchQuery({});
    setPage(1);
  }

  // useEffect(() => {
  //   if (_.isEmpty(searchQuery)) {
  //     initApi();
  //   }
  // }, [searchQuery])

  const searchOwner = () => {
    console.log("searchQuery", searchQuery)
    setPage(1);
    initApi();
  }


  const ShowOwnerSection = (item) => {
    setShowOwnerInfo(true);
    setOwnerInfo(item);

  }

  useEffect(() => {
    initApi();
  }, [page, limit])

  const initApi = () => {
    AdminService.getOwnersPg(page, limit, searchQuery).then(data => {
      console.log("Data getOwners", data.data.users)
      setOwners(data.data.users);
      setFilteredOwners(data.data.users);
      setTotalPages(data.data.totalPages);
      if (ownerInfo) {
        try {
          const ownerData = data.data.users.filter(item => item._id === ownerInfo._id)
          setOwnerInfo(ownerData[0])
        } catch (e) {
          console.log('Exception', e)
        }
      } else {
        console.log('owner info not available')
      }
    })
  }
  const nextPage = () => {
    if (page < totalPages) {
      const newPage = page + 1;
      setPage(newPage);
      updateURLParams(newPage, limit);
    }
  };
  const prevPage = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      updateURLParams(newPage, limit);
    }
  };

  const updateOwnerInfo = (updatedOwner) => {
    const addressUpdate = {
      address: updatedOwner.address
    };
    AdminService.updateProfile(updatedOwner._id, addressUpdate)
      .then(() => {
        console.log("Owner updated successfully!");
        initApi();
      })
      .catch((error) => {
        console.error("Error updating owner:", error);
      });
  };
  const handleChangeSearch = (searchQuery) => {
    if (!searchQuery) {
      setFilteredOwners(owners);
      return;
    }

    let filtered = owners.filter((item) => {
      return (
        (item.mobile && item.mobile.toString().match(new RegExp(searchQuery, "gi"))) ||
        (item.name && item.name.toString().match(new RegExp(searchQuery, "gi"))) ||
        (item._id && item._id.toString().match(new RegExp(searchQuery, "gi")))
      );
    });

    setFilteredOwners(filtered);
  };
  const handleModal = (isVisible) => {
    setVisibleModal(isVisible)
  }
  const showVihicleInfo = (item) => {
    // alert(JSON.stringify(item));
    setVisibleModal(true);
    setCarInfo(item);
  }

  // Update URL when limit changes
  useEffect(() => {
    updateURLParams(page, limit);
  }, [page, limit]);

  return (
    <Layout
      modal={<CarInfo handleModal={handleModal} ownerInfo={ownerInfo} carInfo={carInfo} />}
      visibleModal={visibleModal}
      handleModal={handleModal}
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.owner.key}>

      <div className="flex w-full ">
        <div className="-ml-8 w-full ">

          <OwnerSearch
            searchQuery={searchQuery}
            handleSearchQuery={handleSearchQuery}
            clearSearch={clearSearch}
            searchOwner={searchOwner}
          />

          <OwnerTable
            owners={filteredOwners}
            // owners={owners}
            ShowOwnerSection={ShowOwnerSection}
            updateOwnerInfo={updateOwnerInfo}
          />
          <div className="flex justify-center items-center mt-6 space-x-4">
            <Pagination
              page={page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </div>
        </div>
        {showOwnerInfo &&
          <Modal onClose={() => setShowOwnerInfo(false)}>
            <OwnerInformation
              ownerInfo={ownerInfo}
              setShowOwnerInfo={setShowOwnerInfo}
              refresh={initApi}
              showVihicleInfo={showVihicleInfo}
              showOwnerInfo={showOwnerInfo}
              updateOwnerInfo={updateOwnerInfo}
            />
          </Modal>
        }
      </div>
    </Layout>
  )
};

const Modal = ({ children, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
    <div className="p-6">
      {children}
    </div>
  </div>
);
export default Owners;
