import React from "react";

const JSONView = ({ data, onClose }) => {
  const renderValue = (value) => {
    if (typeof value === "object" && value !== null) {
      return renderObject(value);
    } else {
      return <span>{String(value)}</span>;
    }
  };

  const renderObject = (obj) => {
    return (
      <ul className="ml-10">
        {Object.entries(obj).map(([key, value], index) => (
          <li key={index}>
            <strong>{key}: </strong>
            {renderValue(value)}
          </li>
        ))}
      </ul>
    );
  };

  // return renderValue(data)

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
      <div
        className="bg-white p-8 rounded-lg shadow-lg z-10 max-w-md flex flex-col"
        style={{ maxHeight: "80vh", overflowY: "auto" }}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Transaction Details</h2>
          <button
            onClick={onClose}
            className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Close
          </button>
        </div>
        {renderValue(data)}
      </div>
    </div>
  );
};

export default JSONView;
