import React from "react";
import moment from "moment";

function ChatCard({ item }) {
  const isAdmin = item.user.type === "admin" || item.user.type === "support";

  return (
    <div className={`flex ${isAdmin ? "justify-end" : "justify-start"} mb-4`}>
      <div className={`flex flex-col max-w-[70%] ${isAdmin ? "items-end" : "items-start"}`}>
        <div className={`flex items-center mb-1 ${isAdmin ? "flex-row-reverse" : ""}`}>
          <div className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-bold ${isAdmin ? "bg-indigo-600 ml-2" : "bg-green-500 mr-2"}`}>
            {item.user.name.charAt(0).toUpperCase()}
          </div>
          <span className="text-sm font-semibold text-gray-700">{item.user.name}</span>
        </div>
        <div className={`rounded-lg p-3 ${isAdmin ? "bg-indigo-100 text-indigo-900" : "bg-green-50 text-green-800"} shadow-md`}>
          <p className="text-sm">{item.text}</p>
        </div>
        <div className={`text-xs text-gray-500 mt-1 ${isAdmin ? "text-right" : "text-left"}`}>
          {moment(item.createdAt).format("MMM D, YYYY h:mm A")}
        </div>
      </div>
    </div>
  );
}

export default ChatCard;