import React, { useState, useEffect } from "react";
import moment from 'moment';
import ImageS3 from "../common/ImageS3";
import BidTableInfo from "./bid_table_info";
import AdminService from "../../services/admin/AdminService";
import TripEditModal from "../common/TripEditModal";
import TripService from "../../services/TripService";
import toast from "react-hot-toast";

const TripTable = ({ trips }) => {
  const [tripList, setTripList] = useState();
  const [openDetailId, setOpenDetailId] = useState(null);
  const [bidInfo, setBidInfo] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [comment, setComment] = useState("");
  const [monitor, setMonitor] = useState("");


  const handleComment = (trip) => {

    setSelectedTrip(trip);
    setMonitor(trip?.tripMonitoring?.monitoringBy || "");
    setComment(trip?.tripMonitoring?.comment || "");
    setShowPopup(true);
  };
  const handleSave = () => {
    const updatedTrip = {
      ...selectedTrip,
      tripMonitoring: {
        ...selectedTrip.tripMonitoring,
        monitoringBy: monitor,
        comment: comment,
      },
    };
    handleSaveTrip(updatedTrip);
    setShowPopup(false);
  };
  useEffect(() => {
    setTripList(trips)
  }, [trips])

  const tableHeaders = [
    // "Trip ID",
    "From",
    "To",
    "Bid Count",
    "Trip Type",
    "Car Type",
    "Created Date",
    "Start Date",
    "Monitoring By",
    "Trip Status",
    "View Details",
    "Action"
  ];

  const toggleDetails = async (trip) => {
    if (openDetailId === trip._id) {
      setOpenDetailId(null);
    } else {
      setOpenDetailId(trip._id);
      if (trip.bidCount > 0) {
        const data = await AdminService.bids(trip._id);
        setBidInfo(data.data.bids);
      } else {
        setBidInfo(null);
      }
    }
  };

  const editTripDetails = (trip) => {
    setSelectedTrip(trip)
    setIsEditModalOpen(true);
  };


  const handleSaveTrip = async (editedTrip) => {
    try {
      TripService.updateTrip(editedTrip._id, editedTrip)
        .then((res) => {
          toast.success("Trip updated successfully");
          const updatedTripList = tripList.map(trip => trip._id === res.data._id ? res.data : trip);
          setTripList(updatedTripList);
          setIsEditModalOpen(false);
          setSelectedTrip(null);
        })
        .catch((error) => {
          console.error('Error updating trip:', error);
          toast.error("Error updating trip");
        });
    } catch (error) {
      console.error('Error updating trip:', error);
      toast.error("Error updating trip");
    }
  };

  const TripDetails = ({ trip }) => (
    <div className="px-6 py-4 bg-gray-50 border-t border-gray-20">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h4 className="font-semibold text-lg text-indigo-600 mb-3">Passenger Details</h4>
          <div className="flex items-center mb-2">
            <ImageS3
              uri={trip?.passenger?.profilePicture}
              style={"h-16 w-16 rounded-full"}
            />
            <div className="ml-3">
              <p className="font-medium">{trip?.passenger?.name}</p>
              <p className="text-sm text-gray-600">{trip?.passenger?.mobile}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow capitalize">
          <h4 className="font-semibold text-lg text-indigo-600 mb-3">Trip Details </h4>
          <h5 className="font-semibold text-lg text-gray-600 mb-3">
            Trip Id :
            <span className="bg-gray-100 text-gray-800 text-sm px-2 py-1 rounded-md ml-2">
              {trip._id}
            </span>
          </h5>
          <div className="mb-4 bg-blue-50 p-3 rounded-lg border-l-4 border-blue-500">
            <h5 className="text-blue-700 font-medium mb-2">From Location</h5>
            <p className="text-gray-800"><span className="font-medium">Location:</span> {trip?.fromLocation?.name} ({trip?.fromLocation?.district})</p>
            <p className="text-gray-600 italic"><span className="font-medium">Note:</span> {trip?.fromLocation?.note}</p>
          </div>
          <div className="bg-indigo-50 p-3 rounded-lg border-l-4 border-indigo-500">
            <h5 className="text-indigo-700 font-medium mb-2">To Location</h5>
            <p className="text-gray-800"><span className="font-medium">Location:</span> {trip?.toLocation?.name} ({trip?.toLocation?.district})</p>
            <p className="text-gray-600 italic"><span className="font-medium">Note:</span> {trip?.toLocation?.note}</p>
          </div>
          <p><span className="font-medium">Vehicle:</span> {trip?.vehicleType}{trip?.vehicleSeats ? ` (${trip?.vehicleSeats} Seats)` : ''}</p>
          <p><span className="font-medium">Start Date Time:</span> {moment(trip?.startDate).format('ll')} {trip?.startTime}</p>
          {
            trip?.tripType === 'two-way' &&
            <p><span className="font-medium">Back Date Time:</span> {moment(trip?.backDate).format('ll')} {trip?.backTime}</p>
          }
          <p><span className="font-medium">Trip Type:</span> {trip?.tripType}</p>
          <p><span className="font-medium">Status:</span> <span className={`capitalize font-semibold ${trip.tripStatus === 'completed' ? 'text-green-600' :
            trip.tripStatus === 'canceled' ? 'text-red-600' :
              'text-yellow-600'
            }`}>{trip.tripStatus}</span></p>
          {trip.startCode && <p className="bg-yellow-100"><span className="font-medium">Start Code:</span> {trip.startCode}</p>}
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <h4 className="font-semibold text-lg text-indigo-600 mb-3">Fare Details</h4>
          <p><span className="font-medium">Trip Fare:</span> ৳{trip?.fare?.amount}</p>
          {trip?.fare?.fare_round_trip > 0 && <p><span className="font-medium">Round Trip Fare:</span> ৳{trip?.fare?.fare_round_trip}</p>}
          {trip?.fare?.fare_u_turn_trip > 0 && <p><span className="font-medium">U-Turn Trip Fare:</span> ৳{trip?.fare?.fare_u_turn_trip}</p>}
          <p><span className="font-medium">Driver Booking:</span> ৳{trip?.booking?.driver?.bookingValue}{trip?.booking?.driver?.bookingType === 'fixed' ? '(Fixed)' : '%'} </p>
          <p><span className="font-medium">Passenger Booking:</span> ৳{trip?.booking?.passenger?.bookingValue}{trip?.booking?.passenger?.bookingType === 'fixed' ? '(Fixed)' : '%'} </p>
        </div>

        {trip.acceptedBid.bidAmount > 0 && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-indigo-600 mb-3">Bid Details</h4>
            <p><span className="font-medium">Bid Count:</span> {trip.bidCount}</p>
            <p><span className="font-medium">Accepted Bid Amount:</span> ৳{trip.acceptedBid.bidAmount}</p>
            <p><span className="font-medium">Driver Amount:</span> ৳{trip.acceptedBid.driverAmount}</p>
            {trip.driver && (
              <div className="mt-2">
                <p><span className="font-medium">Driver Name:</span> {trip.driver.name}</p>
                <p><span className="font-medium">Driver Mobile:</span> {trip.driver.mobile}</p>
              </div>
            )}
          </div>
        )}

        {trip.tripStatus === 'canceled' && trip.canceledBy && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-red-600 mb-3">Cancellation Details</h4>
            <p><span className="font-medium">Canceled On:</span> {moment(trip.canceledBy.cancellationDate).format('lll')}</p>
            <p><span className="font-medium">Canceled By:</span> {
              trip.canceledBy.passenger ? 'Passenger' :
                trip.canceledBy.driver ? 'Driver' :
                  'System (Auto)'
            }</p>
            <p><span className="font-medium">Cancel Type:</span> {trip.canceledBy.cancelType || 'N/A'}</p>
            <p><span className="font-medium">Refunded:</span> {trip.canceledBy.isRefunded ? 'Yes' : 'No'}</p>
          </div>
        )}

        {trip.log && trip.log.length > 0 && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-indigo-600 mb-3">Trip Log</h4>
            {trip.log.map((logEntry, index) => (
              <div key={logEntry._id} className="mb-2">
                <p><span className="font-medium">Action:</span> {logEntry.title}</p>
                <p><span className="font-medium">By:</span> {logEntry.role}</p>
              </div>
            ))}
          </div>
        )}

        {trip.additionalTripInfo && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-indigo-600 mb-3">Additional Trip Info</h4>
            <div className="max-h-120 overflow-y-auto">
              <p className="text-gray-700 whitespace-pre-wrap pr-2">{trip.additionalTripInfo}</p>
            </div>
          </div>
        )}

      </div>

      <div className="mt-4 text-lg font-semibold text-indigo-600">Bids for this trip</div>
      {
        (bidInfo && bidInfo.length > 0) ?
          <div className="grid grid-cols-1">
            <BidTableInfo bidInfo={bidInfo.sort((a, b) => a.bidFare.amount - b.bidFare.amount)} trip={trip} />
          </div>
          :
          <div className="mt-4 text-lg font-semibold text-red-600">No bids found</div>
      }
    </div>
  );

  return (
    <>
      <div className="w-full overflow-x-auto shadow-lg rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {
              tripList &&
              [...tripList].reverse().map((trip, index) => (
                <React.Fragment key={trip._id + index}>
                  <tr className="hover:bg-gray-50 transition-colors duration-200">
                    {/* <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {trip._id}
                      </div>
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {trip.fromLocation?.name} ({trip.fromLocation?.district})
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {trip.toLocation?.name} ({trip.toLocation?.district})
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {trip.bidCount}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 capitalize" style={trip.tripType === 'two-way' ? { color: 'red' } : { color: 'green' }}>
                        {trip.tripType}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {trip.vehicleType} {trip.vehicleSeats ? ` (${trip.vehicleSeats} Seats)` : ''}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {moment(trip.createdAt).format('LL')} {moment(trip.createdAt).format('LT')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {moment(trip.startDate).format('ll')} {trip.startTime}
                    </td>
                    <td className="px-6 py-4  text-sm text-gray-500">
                      <button className={`p-2 px-2 ${trip?.tripMonitoring?.monitoringBy || trip?.tripMonitoring?.comment ? "bg-transparent" : "bg-green-500 text-white border-none px-4 py-2 rounded cursor-pointer"}`} onClick={() => handleComment(trip)}>
                        {trip?.tripMonitoring?.monitoringBy ? (
                          <span className="font-bold text-gray-900">
                            {trip.tripMonitoring.monitoringBy}
                          </span>
                        ) : (
                          "Comment"
                        )}
                        {trip?.tripMonitoring?.comment && (
                          <p className="text-sm">
                            {trip.tripMonitoring.comment.length > 10
                              ? trip.tripMonitoring.comment.substring(0, 10) + "..."
                              : trip.tripMonitoring.comment}
                          </p>
                        )}
                      </button>

                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 capitalize inline-flex text-xs leading-5 font-semibold rounded-full ${trip.tripStatus === 'completed' ? 'bg-green-100 text-green-800' :
                        trip.tripStatus === 'canceled' ? 'bg-red-100 text-red-800' :
                          'bg-yellow-100 text-yellow-800'
                        }`}>
                        {trip.tripStatus}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => toggleDetails(trip)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        {openDetailId === trip._id ? 'Hide Details' : 'View Details'}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => editTripDetails(trip)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                  {showPopup && (
                    <div
                      style={{
                        position: "fixed",
                        width: "400px",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        background: "white",
                        padding: "20px",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="flex flex-col items-start">
                        <label
                          htmlFor="name"
                          style={{
                            display: "block",
                            marginBottom: "5px",
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                          }}
                        >
                          Name:
                        </label>
                        <input
                          type="text"
                          placeholder="Enter your Name"
                          value={monitor}
                          onChange={(e) => setMonitor(e.target.value)}
                          maxLength={20}
                          style={{
                            padding: "10px",
                            width: "100%",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                          }}
                        />
                        <label
                          htmlFor="comment"
                          style={{
                            display: "block",
                            marginTop: "10px",
                            marginBottom: "5px",
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                          }}
                        >
                          Comment:
                        </label>
                        <textarea
                          placeholder="Enter your comment"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          maxLength={1000}
                          style={{
                            padding: "10px",
                            width: "100%",
                            height: "100px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            resize: "none",
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                        <button
                          onClick={handleSave}
                          className="bg-green-500 text-white border-none px-4 py-2 rounded cursor-pointer"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setShowPopup(false)}
                          style={{
                            background: "red",
                            color: "white",
                            border: "none",
                            padding: "10px 15px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}

                  {openDetailId === trip._id && (
                    <tr>
                      <td colSpan="7">
                        <div className="max-w-full">
                          <TripDetails key={index} trip={trip} />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>

      <TripEditModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        trip={selectedTrip}
        onSave={handleSaveTrip}
      />
    </>
  );
};

export default TripTable;