import React, { useEffect, useState } from "react";
import AdminService from "../../services/admin/AdminService";
import { Header } from "../../components";
import Layout from "../../components/Layout/index";
import { sideBarItems } from "../../utils";
import DriverTable from "../../components/Tables/driver_table";

import TripService from "../../services/TripService";
import _ from "lodash";
import TripTable from "../../components/Tables/trip_table";
import { Pagination } from "../../components/common/pagination";
import TripSearch from "../../components/TripSearch";

function TripList({ history, location }) {
  const [trips, setTrips] = useState([]);
  const [isedit, setIsedit] = useState(true);
  const [filteredTrips, setFilteredTrips] = useState([]);

  const [showbidinfo, setshowbidinfo] = useState(false);
  const [bidinfo, setBidinfo] = useState();

  const [searchQuery, setSearchQuery] = useState({});

  // for pagination state
  const query = new URLSearchParams(location.search);
  const [page, setPage] = useState(parseInt(query.get('page')) || 1);
  const [limit, setLimit] = useState(parseInt(query.get('limit')) || 15);
  const [totalPages, setTotalPages] = useState(1);

  const updateURLParams = (newPage, newLimit) => {
    const params = new URLSearchParams();
    params.set('page', newPage);
    params.set('limit', newLimit);
    history.replace({ search: params.toString() });
  };

  console.log("search: ", filteredTrips)

  const ShowDriveSection = (item) => {
    setshowbidinfo(true);
    setBidinfo(item);
  };

  console.log("new_trips", trips);

  useEffect(() => {
    init().catch();
  }, [page, limit]);

  // Update URL when limit changes
  useEffect(() => {
    updateURLParams(page, limit);
  }, [page, limit]);


  // useEffect(() => {
  //   if (_.isEmpty(searchQuery)) {
  //     init();
  //   }
  // }, [searchQuery])

  const init = async () => {
    console.log('init page api call', page, 'limit', limit, 'searchQuery', searchQuery)
    // const tripStatus = 'initiated'
    // setSearchQuery({ tripStatus })
    const data = await TripService.getTripsV2({ page, limit, searchQuery });
    console.log("all data", data.data.pagination);
    const res = data.data
    console.log(res.pagination)
    const sortedData = _.orderBy((_.isArray(res?.data) && res?.data) || [], ['createdAt'], ['asc']);
    setTrips(sortedData);
    setFilteredTrips(sortedData);
    setTotalPages(res.pagination.totalPages)
  };
  const nextPage = () => {
    console.log("page", page)
    if (page < totalPages) {
      const newPage = page + 1;
      setPage(newPage);
      updateURLParams(newPage, limit);
    }
  };
  const prevPage = () => {
    if (page > 1) {
      const newPage = page - 1;
      setPage(newPage);
      updateURLParams(newPage, limit);
    }
  };
  const handleChangeSearch = (searchQuery) => {
    let filteredTrips = _.filter(trips, (item) => {
      return (
        item?.fromLocation?.name?.match(new RegExp(searchQuery, "gi"))
        || item?.toLocation?.name?.match(new RegExp(searchQuery, "gi"))
        || item?._id?.match(new RegExp(searchQuery, "gi"))
      );
    });
    setFilteredTrips(filteredTrips);
  };

  const handleSearchQuery = (type, value) => {
    switch (type) {
      case 'name':
      case 'mobile':
      case 'tripStatus':
      case 'fromDistrict':
      case 'toDistrict':
      case 'vehicleType':
      case 'vehicleSeats':
      case 'startDate' : 
      case 'createdAt' :
      case '_id':
        if (value) {
          setSearchQuery(prev => ({ ...prev, [type]: value }));
        } else {
          setSearchQuery(prev => {
            const newQuery = { ...prev };
            delete newQuery[type];
            return newQuery;
          });
        }
        break;
      default:
        break;
    }
  }


  const clearSearch = () => {
    setSearchQuery({});
    setPage(1);
  }


  const searchTrip = () => {
    console.log("searchQuery", searchQuery)
    setPage(1);
    init();
  }

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.trip.key}
    >
      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <TripSearch
            searchQuery={searchQuery}
            handleSearchQuery={handleSearchQuery}
            clearSearch={clearSearch}
            searchTrip={searchTrip}
          />
          <TripTable
            trips={filteredTrips || trips}
            isedit={isedit}
            ShowDriveSection={ShowDriveSection}
          />
          <div className="flex justify-center items-center mt-6 space-x-4">
            <Pagination
              page={page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </div>
        </div>
        {showbidinfo && (
          <div className={"bg-white w-96 shadow-xl rounded p-5 mt-10 mr-6 ml-5 "}>
            <svg
              onClick={() => setshowbidinfo(!showbidinfo)}
              className="w-6 h-6 cursor-pointer "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>{" "}
            <p>{JSON.stringify(bidinfo, null, 2)}</p>
          </div>
          // <DriverTable />
        )}
      </div>
    </Layout>
  );
}

export default TripList;
