const DriverSearch = ({ searchQuery, handleSearchQuery, searchOwner, clearSearch }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-6xl mx-auto ml-0">
       
  
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="text-md font-medium mb-1 text-gray-700">Name</label>
            <input
              type="text"
              placeholder="Search by name"
              value={searchQuery.name || ""}
              onChange={(e) => handleSearchQuery("name", e.target.value)}
              className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-md font-medium mb-1 text-gray-700">Mobile</label>
            <input
              type="number"
              placeholder="Search by mobile"
              value={searchQuery.mobile || ""}
              onChange={(e) => {
                let value = e.target.value;
                if (value.startsWith("88")) {
                  value = value.slice(2);
                }
                const errorDiv =
                  e.target.parentElement.querySelector(".error-message") || document.createElement("div");
                errorDiv.className = "error-message text-red-500 text-sm mt-1";
  
                if (value.length < 11 && value.length > 0) {
                  errorDiv.textContent = "Phone number must be 11 digits";
                } else {
                  errorDiv.textContent = "";
                }
  
                if (!e.target.parentElement.querySelector(".error-message")) {
                  e.target.parentElement.appendChild(errorDiv);
                }
  
                if (/^[0-9]{0,11}$/.test(value)) {
                  handleSearchQuery("mobile", value);
                }
              }}
              className="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-6">
          <button
            className="px-5 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-all"
            onClick={clearSearch}
          >
            Clear
          </button>
          <button
            className="px-5 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-all"
            onClick={searchOwner}
            onKeyDown={(e) => e.key === "Enter" && searchOwner()}
          >
            Search
          </button>
        </div>
      </div>
    );
  };
  
  export default DriverSearch;
  