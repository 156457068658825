import React, { useState, useCallback } from "react";
import _ from 'lodash';
import Conformation from "../../components/common/conformation";
import AuthService from "../../services/owner/AuthService";
import useAuthentication from "../../components/HOC/useAuthentication";
import ImageS3 from "../../components/common/ImageS3";
import LocationService from "../../services/owner/LocationService";



// Extracted components
const ProfileSection = ({ ownerInfo }) => (
  <div className="flex flex-col items-center space-y-4">
    <div className="relative">
      <ImageS3 uri={ownerInfo?.profile} style="h-32 w-32 rounded-full ring-4 ring-indigo-50" />
      {ownerInfo?.isVerifiedAsOwner && (
        <div className="absolute -bottom-1 -right-1 bg-green-500 rounded-full p-2">
          <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
          </svg>
        </div>
      )}
    </div>
    <h2 className="text-2xl font-semibold text-gray-800">
      {ownerInfo?.name}
      {(ownerInfo?.firstName || ownerInfo?.lastName) && (
        <span className="text-gray-600 text-lg">
          {" "}({ownerInfo?.firstName || ""} {ownerInfo?.lastName || ""})
        </span>
      )}
    </h2>
  </div>
);


const VehicleTable = ({ vehicles, onManage }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
      <thead className="bg-gray-100 border-b">
        <tr>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">#</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Status</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Vehicle</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Registration</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Seats</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Car Image Verification</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Smart Card</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Tax Token</th>
          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Fitness</th>

          <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Action</th>
        </tr>
      </thead>
      <tbody>
        {vehicles?.map((vehicle, index) => (
          <tr key={vehicle?._id} className="border-b hover:bg-gray-50">
            <td className="px-4 py-2 text-sm text-gray-900">{index + 1}</td>
            <td className="px-4 py-2 text-sm text-gray-900 font-bold">
              {vehicle?.vehicle?.verifiedStatus === 'verified' ? "✅"
                : "❌"}
            </td>
            <td className="px-4 py-2 text-sm text-gray-900 font-bold">
              {vehicle?.vehicle?.carInfo?.brand} {vehicle?.vehicle?.carInfo?.model} {vehicle?.vehicle?.carInfo?.year}
            </td>
            <td className="px-4 py-2 text-sm text-gray-900">
              {vehicle?.vehicle?.registration?.cityName}{" "}
              {vehicle?.vehicle?.registration?.vehicleCategory} -{" "}
              {vehicle?.vehicle?.registration?.vehicleNumber}
            </td>
            <td className="px-4 py-2 text-sm">{vehicle?.vehicle?.seats ? vehicle?.vehicle?.seats : "❌"}</td>
            <td className="px-4 py-2 text-sm">
              {(vehicle?.vehicle?.docs?.vehicleNumberPlate?.uri || vehicle?.vehicle?.docs?.vehicleFront?.uri) ||
                (vehicle?.vehicle?.docs?.vehicleNumberPlate?.uri || vehicle?.vehicle?.docs?.vehicleFont?.uri) ||
                (vehicle?.vehicle?.docs?.vehicleFront?.uri || vehicle?.vehicle?.docs?.vehicleFont?.uri)
                ? "✅"
                : "❌"}
            </td>
            <td className="px-4 py-2 text-sm">{vehicle?.vehicle?.docs?.smartCard?.uri ? "✅" : "❌"}</td>
            <td className="px-4 py-2 text-sm">{vehicle?.vehicle?.docs?.taxToken?.uri ? "✅" : "❌"}</td>
            <td className="px-4 py-2 text-sm">{vehicle?.vehicle?.docs?.fitness?.uri ? "✅" : "❌"}</td>
            <td className="px-4 py-2 text-sm">
              <button
                onClick={() => onManage(vehicle)}
                className="px-4 py-1 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition"
              >
                Manage
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);


const DriverTable2 = ({ drivers, onVerifyDriver }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const handleVerifyClick = (driver) => {
    onVerifyDriver(driver);
  };
  const getName = (driveinfo) => {
    if (driveinfo?.name) return driveinfo.name;
    if (driveinfo?.myOwners?.length > 0) return driveinfo.myOwners[0].name;
    return "N/A";
  };
  const getDrivingType = (driver) => {
    if (driver?.myOwners?.length > 0) {
      const drivingType = driver?.myOwners[0]?.drivingType;
      switch (drivingType?.toLowerCase()) {
        case 'part time':
          return <span className="text-yellow-600 font-medium text-sm bg-yellow-100 px-2 py-1 rounded-full capitalize">Part Time</span>;
        case 'full time':
          return <span className="text-green-600 font-medium text-sm bg-green-100 px-2 py-1 rounded-full capitalize">Full Time</span>;
        case 'self':
          return <span className="text-red-600 font-medium text-sm bg-red-100 px-2 py-1 rounded-full capitalize">Self</span>;
        default:
          return 'N/A';
      }
    }
    return 'N/A';

  };


  // Update the toggleRow function
  const toggleRow = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
        <thead className="bg-gray-100 border-b">
          <tr>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">#</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Name</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Mobile</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Status</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">NID</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Driving License</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Driver Details</th>

            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">DriverType</th>
            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-700">Action</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {drivers.map((item, index) => (
            <React.Fragment key={item._id}>
              <tr>
                <td className="px-4 py-2 text-sm text-gray-900">{index + 1}</td>
                <td className="px-6 py-4  capitalize">
                  <div className="text-sm font-medium text-gray-900 flex flex-row gap-2 items-center w-40">
                    <p className="font-bold">{getName(item.driver)}</p>
                    {getDrivingType(item.driver)}
                  </div>
                </td>
                <td className="px-4 py-2 text-sm text-gray-900 font-bold">
                  {item?.driver?.mobile}
                </td>
                <td className={`px-4 py-2 text-sm text-gray-900 font-bold w-60`}>
                  <p className={`px-4 py-1 text-black rounded-md transition ${!item?.driver?.isVerifiedAsDriver
                    ? "bg-red-300 hover:bg-red-400"
                    : "bg-green-300 hover:bg-green-00"
                    }`}>
                    {!item?.driver?.isVerifiedAsDriver
                      ? "Not Verified"
                      : "Verified"}
                  </p>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item?.driver?.docs?.nidFont?.uri ? "✅" : "❌"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item?.driver?.docs?.drivingLicense?.uri ? "✅" : "❌"}
                </td>

                <td className={`px-6 py-2 text-gray-900 cursor-pointer`}
                  onClick={() => toggleRow(item._id)}>
                  <p className="px-4 py-1 text-black rounded-md transition bg-gray-300 ">View Details</p>
                </td>
                <td className="px-4 py-2 text-sm text-gray-900 font-bold">
                  {item?.driver?.myOwners[0]?.drivingType?.toUpperCase()}
                </td>
                <td className="px-4 py-2 text-sm text-gray-900 font-bold w-40">
                  <button
                    onClick={() => handleVerifyClick(item)}
                    className={`px-4 py-1 text-white rounded-md transition ${
                      // verificationStatus[driver.driver._id] || driver?.driver?.isVerifiedAsDriver
                      item?.driver?.isVerifiedAsDriver
                        ? "bg-red-600 hover:bg-red-700"
                        : "bg-green-600 hover:bg-green-700"
                      }`}
                  >

                    {!item?.driver?.isVerifiedAsDriver
                      ? "Verify Driver"
                      : "Cancel Verification"}
                  </button>
                </td>
              </tr>
              {expandedRow === item._id && (
                <tr>
                  <td colSpan="9" className="px-6 py-8 bg-gray-50">
                    <div className="max-w-5xl mx-auto">
                      {/* Documents Grid */}
                      <div className="grid grid-cols-2 gap-8 mb-8">
                        {/* NID Section */}
                        <div className="bg-white p-4 rounded-lg shadow">
                          <h4 className="font-medium mb-4 text-gray-700">National ID Card</h4>
                          {item?.driver?.docs?.nidFont?.uri ? (
                            <ImageS3
                              uri={item.driver.docs.nidFont.uri}
                              style="w-full h-48 object-cover rounded-lg"
                            />
                          ) : (
                            <div className="bg-gray-100 h-48 rounded-lg flex items-center justify-center">
                              <p className="text-gray-500">No NID uploaded</p>
                            </div>
                          )}
                        </div>

                        {/* Driving License Section */}
                        <div className="bg-white p-4 rounded-lg shadow">
                          <h4 className="font-medium mb-4 text-gray-700">Driving License</h4>
                          {item?.driver?.docs?.drivingLicense?.uri ? (
                            <ImageS3
                              uri={item.driver.docs.drivingLicense.uri}
                              style="w-full h-48 object-cover rounded-lg"
                            />
                          ) : (
                            <div className="bg-gray-100 h-48 rounded-lg flex items-center justify-center">
                              <p className="text-gray-500">No license uploaded</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const OwnerInformation = ({ ownerInfo, setShowOwnerInfo, showOwnerInfo, updateOwnerInfo, refresh, showVihicleInfo }) => {
  const { user } = useAuthentication();
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // Address state
  const [addressData, setAddressData] = useState({
    division: ownerInfo?.address?.division || '',
    district: ownerInfo?.address?.district || '',
    subDistrict: ownerInfo?.address?.subDistrict || '',
    presentAddress: ownerInfo?.address?.presentAddress || '',
    permanentAddress: ownerInfo?.address?.permanentAddress || '',
    city: ownerInfo?.address?.city || '',
  });

  // Location data state
  const [locationData, setLocationData] = useState({
    divisions: [],
    districts: [],
    subDistricts: [],
  });

  const handleAddressChange = (field, value) => {
    setAddressData(prev => ({ ...prev, [field]: value }));
  };

  const handleSaveAddress = () => {
    const payload = {
      city: _.startCase(_.toLower(addressData.city)),
      subDistrict: _.startCase(_.toLower(addressData.subDistrict)),
      district: _.startCase(_.toLower(addressData.district)),
      division: addressData.division,
      presentAddress: addressData.presentAddress,
      permanentAddress: addressData.permanentAddress,
    };

    updateOwnerInfo?.({
      ...ownerInfo,
      address: payload,
    });

    setIsEditing(false);
  };

  const fetchLocationData = useCallback(async (type, params = {}) => {
    try {
      let data;
      switch (type) {
        case 'divisions':
          data = await LocationService.getDivisions();
          setLocationData(prev => ({ ...prev, divisions: data || [] }));
          break;
        case 'districts':
          data = await LocationService.getLocations({ ...params, type: 'district' });
          setLocationData(prev => ({ ...prev, districts: data || [] }));
          break;
        case 'subDistricts':
          data = await LocationService.getLocations({ ...params, type: 'subDistrict' });
          setLocationData(prev => ({ ...prev, subDistricts: data || [] }));
          break;
      }
      return data;
    } catch (error) {
      console.error(`Error fetching ${type}:`, error);
      return [];
    }
  }, []);

  const handleEditAddress = async () => {
    let localDistrict = _.cloneDeep(addressData.district);
    let localSubDistrict = _.cloneDeep(addressData.subDistrict);
    if (!isEditing) {
      await fetchLocationData('divisions');

      if (!addressData.division) {
        handleAddressChange('division', locationData.divisions[0]?.title);
      }

      if (addressData.division) {
        await fetchLocationData('districts', { division: addressData.division });
        if (!addressData.district) {
          handleAddressChange('district', locationData.districts[0]?.district);
          localDistrict = locationData.districts[0]?.district;
        }
      } else {
        await fetchLocationData('districts', { division: locationData.divisions[0]?.title });
        if (!addressData.district) {
          const district = locationData.districts[0]?.district;
          handleAddressChange('district', district);
          localDistrict = district;
        }
      }

      if (localDistrict) {
        await fetchLocationData('subDistricts', { district: localDistrict });
        if (!addressData.subDistrict) {
          handleAddressChange('subDistrict', locationData.subDistricts[0]?.name);
          localSubDistrict = locationData.subDistricts[0];
        }
      } else {
        await fetchLocationData('subDistricts', { district: locationData.districts[0]?.district });
        if (!addressData.subDistrict) {
          handleAddressChange('subDistrict', locationData.subDistricts[0]?.name);
          localSubDistrict = locationData.subDistricts[0];
        }
      }
    }
    setIsEditing(!isEditing);
  }

  const handleDivisionChange = async (division) => {
    handleAddressChange('division', division);
    await fetchLocationData('districts', { division: division });

    const subDistricts = await LocationService.getLocations({
      district: locationData.districts[0]?.district,
      type: "subDistrict",
    });
    setLocationData(prev => ({ ...prev, subDistricts: subDistricts || [] }));

    handleAddressChange('district', locationData.districts[0]?.district);
    handleAddressChange('subDistrict', locationData.subDistricts[0]?.name);
  }

  const handleDistrictChange = async (district) => {
    handleAddressChange('district', district);
    await fetchLocationData('subDistricts', { district: district });
    if (!addressData.subDistrict) {
      handleAddressChange('subDistrict', locationData.subDistricts[0]?.name);
    } else {
      const matchSubDistrict = locationData.subDistricts.find((item) => item?.name === addressData.subDistrict);
      handleAddressChange('subDistrict', matchSubDistrict?.name || locationData.subDistricts[0]?.name);
    }
  }

  const handleVerifyDriver = async (driver) => {
    try {
      const response = await AuthService.verifyDriver(user?.user?._id, driver?.driver?._id);
      if (response) {
        refresh();
      }
    } catch (error) {
      console.error("Error verifying driver:", error);
    }
  };
  return (
    <div
      className="bg-white rounded-xl shadow-lg p-6 max-w-7xl mx-auto"
      style={{ maxHeight: "80vh", overflowY: "auto", width: "80vw" }}
    >
      {visibleConformation && (
        <Conformation
          title={"Verify Owner"}
          msg={`${ownerInfo?.isVerifiedAsOwner
            ? "Are you sure to cancel verification of this owner?"
            : "Are you sure to verify this owner?"
            }`}
          status={ownerInfo?.isVerifiedAsOwner}
          confirm={() => {
            AuthService.verifyOwner(
              user?.user?._id,
              ownerInfo?._id
            ).then((data) => {
              if (refresh) {
                setShowOwnerInfo(!showOwnerInfo);
                refresh();
              }
            });
          }}
        />
      )}

      {/* Header with close button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={() => setShowOwnerInfo(!showOwnerInfo)}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <svg
            className="w-6 h-6 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <div className="space-y-6">
        {/* Profile Section */}
        <ProfileSection ownerInfo={ownerInfo} />

        <div className="flex flex-row w-full gap-4">
          {/* Info Grid */}
          <div className="grid grid-cols-2 gap-6 bg-gray-50 p-6 rounded-xl flex-1">
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <svg
                  className="w-5 h-5 text-indigo-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <div>
                  <p className="text-sm text-gray-500">Phone</p>
                  <p className="font-medium">{ownerInfo?.mobile}</p>
                </div>
              </div>


              <div className="flex items-center space-x-3">
                <svg
                  className="w-5 h-5 text-indigo-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div>
                  <p className="text-sm text-gray-500">Status</p>
                  <p
                    className={`font-medium ${ownerInfo?.isVerifiedAsOwner
                      ? "text-green-600"
                      : "text-red-600"
                      }`}
                  >
                    {ownerInfo?.isVerifiedAsOwner
                      ? "Verified"
                      : "Not Verified"}
                  </p>
                </div>
              </div>
            </div>

            {/* Verification Button */}
            <div className="flex items-start justify-end">
              <button
                onClick={() => setVisibilityConformation(!visibleConformation)}
                className={`px-6 py-2.5 rounded-lg font-medium transition-colors duration-200 ${ownerInfo?.isVerifiedAsOwner
                  ? "bg-red-600 hover:bg-red-700 text-white"
                  : "bg-green-600 hover:bg-green-700 text-white"
                  }`}
              >
                {ownerInfo?.isVerifiedAsOwner
                  ? "Cancel Verification"
                  : "Verify Owner"}
              </button>
            </div>
          </div>

          {/* Address */}
          <div className="space-y-4 bg-gray-50 p-6 rounded-xl flex-1">
            <div className="flex items-center">
              <div className="space-y-2 w-full">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2">
                    <svg
                      className="w-5 h-5 text-indigo-600"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <p className="text-sm text-gray-500 font-medium">Address</p>
                  </div>
                  <button
                    onClick={handleEditAddress}
                    className="px-4 py-2 text-sm font-medium ml-4 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 transition-colors duration-200"
                  >
                    {isEditing ? "Cancel" : "Edit"}
                  </button>
                </div>
                {isEditing ? (
                  <div className="space-y-2">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="flex items-start flex-col">
                        <label
                          htmlFor="city"
                          className="block text-gray-700 font-medium mb-1"
                        >
                          Division
                        </label>
                        <select
                          value={addressData.division}
                          onChange={(e) => handleDivisionChange(e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        >
                          {locationData.divisions.map((item, index) => (
                            <option key={index} value={item?.title}>{item?.title}</option>
                          ))}
                        </select>
                      </div>
                      <div className="flex items-start flex-col">
                        <label
                          htmlFor="District"
                          className="block text-gray-700 font-medium mb-1"
                        >
                          District
                        </label>
                        <select
                          value={addressData.district}
                          onChange={(e) => handleDistrictChange(e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        >
                          {locationData.districts.map((item, index) => (
                            <option key={index} value={item?.district}>{item?.district}</option>
                          ))}
                        </select>
                      </div>
                      <div className="flex items-start flex-col">
                        <label
                          htmlFor="city"
                          className="block text-gray-700 font-medium mb-1"
                        >
                          Sub-District
                        </label>
                        <select
                          value={addressData.subDistrict}
                          onChange={(e) => handleAddressChange('subDistrict', e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                        >
                          {locationData.subDistricts.map((item, index) => (
                            <option key={index} value={item?.name}>{item?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <label
                      htmlFor="presentAddress"
                      className="block text-gray-700 font-medium mb-1"
                    >
                      Present Address
                    </label>
                    <input
                      value={addressData.presentAddress}
                      onChange={(e) => handleAddressChange('presentAddress', e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                      placeholder="Present Address"
                    />

                    <label
                      htmlFor="permanentAddress"
                      className="block text-gray-700 font-medium mb-1"
                    >
                      Permanent Address
                    </label>
                    <input
                      value={addressData.permanentAddress}
                      onChange={(e) => handleAddressChange('permanentAddress', e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                      placeholder="Permanent Address"
                    />

                    <button
                      onClick={handleSaveAddress}
                      className="px-4 py-2 mt-2 text-sm font-medium text-white bg-green-600 rounded-lg hover:bg-green-700 transition-colors duration-200"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <p className="font-medium">
                    {!addressData.city && !addressData.district && !addressData.subDistrict
                      ? <p className="font-medium">ঠিকানা নেই</p>
                      : <>
                        <p className="font-medium">{`${addressData.subDistrict + ',' || ""} ${addressData.district + ',' || ""} ${addressData.division || ""}`}</p>
                        {addressData.presentAddress && <p className="font-medium">Present Address: {addressData.presentAddress}</p>}
                        {addressData.permanentAddress && <p className="font-medium">Permanent Address: {addressData.permanentAddress}</p>}
                      </>
                    }
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Cars Section */}
        {ownerInfo?.vehicles?.length > 0 && (
          <div className="bg-indigo-50 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              Registered Vehicles
            </h3>
            <div className="space-y-3">
              {/* {ownerInfo?.vehicles?.map((item) => (
                <VehicleCard key={item?._id} vehicle={item} onManage={showVihicleInfo} />
              ))} */}
              <VehicleTable vehicles={ownerInfo?.vehicles} onManage={showVihicleInfo} />
            </div>
          </div>
        )}
        {ownerInfo?.drivers?.length > 0 && (
          <div className="bg-indigo-50 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">
              All Drivers Under The Owner
            </h3>
            <div className="space-y-3">
              <DriverTable2 drivers={ownerInfo?.drivers} onVerifyDriver={handleVerifyDriver} />
            </div>
          </div>
        )}

      </div>
    </div >
  );
};

export default OwnerInformation;
