import React from "react";
import Layout from "../Layout/index";
import Location from "../Tables/location_table";
import { Fragment, useState, useEffect } from "react";
import AdminService from "../../services/admin/AdminService";
import LocationService from "../../services/owner/LocationService";
import "./index.css";
import LocationUpdate from "./locationUpdate";
import UpdateSubDistrict from "./update_sub_district";
import { sideBarItems } from "../../utils";
import _ from "lodash";

const AddLocation = (props) => {
  const [locations, setLocations] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState();
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [subDistricts, setSubDistricts] = useState([]);
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [showUpdatePanel, setUpdatePanelStatus] = useState(false);
  const [showAddPanel, setAddPanelStatus] = useState(false);

  useEffect(() => {
    fetchLocation(selectedDivision).catch();
  }, []);

  const fetchLocation = async (selectedDivision) => {
    setSelectedDistrict(null);
    let division = selectedDivision ? selectedDivision?.title : "Dhaka";
    let district = "Dhaka";

    const divisions = await LocationService.getDivisions();
    setDivisions(divisions || []);
    if (!selectedDivision)
      setSelectedDivision(divisions.length > 0 && divisions[0]);

    const districts = await LocationService.getLocations({
      division,
      type: "district",
    });
    setDistricts(districts || []);
    if (districts.length > 0) {
      district = districts[0]?.name;
      setFilteredLocation(districts || []);
      setLocations(districts || []);
    }
  };

  const fetchSubDistricts = async (selectedDivision, selectedDistrict) => {
    let division = selectedDivision ? selectedDivision?.title : "Dhaka";
    let district = selectedDistrict ? selectedDistrict?.district : "Dhaka";
    const subDistricts = await LocationService.getLocations({ district });
    setSubDistricts(subDistricts || []);
    setFilteredLocation(subDistricts || []);
    setLocations(subDistricts || []);
  };

  const setUpdateLocation = (location) => {
    setSelectedLocation(location);
    setUpdatePanelStatus(true);
    setAddPanelStatus(false);
  };
  const updateLocation = (id, payload) => {
    LocationService.updateLocation(id, payload).then((data) => {
      fetchLocation(selectedDivision);
    });
  };
  const addLocation = (playload) => {
    LocationService.addLocation(playload).then((location) => {
      fetchLocation(selectedDivision);
    });
  };
  const removeLocation = (id) => {
    LocationService.deleteLocation(id).then((data) => {
      fetchLocation(selectedDivision);
    });
  };
  const activeStatusCheck = (id) => {
    LocationService.activeStatus(id).then((data) => {
      fetchLocation(selectedDivision);
    });
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredLocation = _.filter(locations, (item) => {
      return item?.name?.match(new RegExp(searchQuery, "gi"));
    });
    setFilteredLocation(filteredLocation);
  };

  const Divisions = () => {
    return (
      <div className="container py-4 grid sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8">
        {divisions?.map((item) => {
          const changeDivision = () => {
            setSelectedDivision(item);
            fetchLocation(item);
          };
          const isActive = item?.title === selectedDivision?.title;
          const color = isActive ? "bg-red-700" : "bg-blue-400";
          return (
            <button
              onClick={changeDivision}
              className={`text-white font-semibold py-1 px-1.5  rounded-full m-1 ${color}`}
            >
              {item?.title}
            </button>
          );
        })}
      </div>
    );
  };

  const Districts = () => {
    return (
      <div className="container grid  sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-8">
        {districts?.map((item) => {
          console.log("isDeleted: ", item.name, item.deleted);
          const changeDivision = () => {
            if (item?.name == selectedDistrict?.name) {
              setSelectedDistrict(null);
              fetchLocation(selectedDivision);
            } else {
              setSelectedDistrict(item);
              fetchSubDistricts(selectedDivision, item);
            }
          };
          const isActive = item?.name === selectedDistrict?.name;
          const color = isActive ? "700" : "400";
          return (
            <button
              onClick={changeDivision}
              className={`text-white font-medium py-1 px-1.5 rounded-full m-2 bg-blue-${color}`}
            >
              {item?.name}
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.location.key}
    >
      <div className="flex w-full">
        <div className="-ml-6 w-full mr-10 ">
          <button
            onClick={() => {
              setAddPanelStatus(!showAddPanel);
              setUpdatePanelStatus(false);
            }}
            className="fixed bottom-4 right-4 inline-flex items-center justify-center px-2 py-1.5 text-indigo-600 font-medium border border-indigo-600 rounded-md transition duration-300 ease-in-out bg-indigo-100 hover:bg-indigo-200 hover:text-indigo-700 hover:border-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
            <svg
              className="w-5 h-5 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            Add Location
          </button>
          <Divisions />

          <Districts />
          <Location
            setUpdatePanelStatus={setUpdateLocation}
            locations={filteredLocation}
            removeItems={removeLocation}
            itemStatus={activeStatusCheck}
          />
        </div>
        {showUpdatePanel && (
          // <LocationUpdate setLocationdisable={setLocationdisable} locationdisable={locationdisable}/>
          <UpdateSubDistrict
            fetchLocation={fetchLocation}
            selectedLocation={selectedLocation}
            updateLocation={updateLocation}
            setUpdatePanelStatus={(status) => {
              setUpdatePanelStatus(status);
              setAddPanelStatus(false);
            }}
            showUpdatePanel={showUpdatePanel}
          />
        )}

        {showAddPanel && (
          <LocationUpdate
            selectedDivision={selectedDivision}
            selectedDistrict={selectedDistrict}
            addLocation={addLocation}
            setAddPanelStatus={setAddPanelStatus}
            showAddPanel={showAddPanel}
          />
        )}
      </div>
    </Layout>
  );
};

export default AddLocation;
