import React from "react";
import moment from "moment";

import dwonArrow from "../../assets/dwonArrow.png";

const BidPass = (props) => {
  // console.log("props.owners",props.owners[0].name);

  return (
    <div className="flex w-full flex-col mt-10 ">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    From
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    TO
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    CarType
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    TripStatus
                  </th>

                  <th scope="col" className="relative px-3 py-3">
                    {/* <span className="sr-only">Edit</span> */}
                    <h3 className="text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Edit
                    </h3>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.owners.map((item) => (
                  <tr>
                    <td className="px-3  flex py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.fromLocation?.name}
                      </div>
                      {/* {item?.isVerifiedAsDriver && (
                        <h2 className="dote_round mt-1.5 "></h2>
                      )} */}
                    </td>

                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item.toLocation?.name}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item?.address?.city}
                      </div>
                      <div className="text-sm text-gray-900">
                        {item?.vehicleType}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap  text-center text-sm font-medium">
                      <div className="text-sm text-gray-900">
                        {moment(item?.startDate).format("lll")}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap  text-center text-sm font-medium">
                      <div className="text-sm text-gray-900">
                        {item?.tripStatus}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap text-center text-sm font-medium">
                      {props.isedit && (
                        <a
                          href="#"
                          // onClick={() => props.ShowDriveSecthom(item)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidPass;
