import http from "./http";
import { API_BASE_URL } from "../config";

export default class PassengerService {
  static getPassenger() {
    return http
      .get(`${API_BASE_URL}/passengers`)
      .then((response) => response.data);
  }
}
