import React from "react";
import { tripStatus } from "../utils";

const TripSearch = ({ searchQuery, handleSearchQuery, clearSearch, searchTrip }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 ">
      <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Trip ID</label>
          <input
            type="text"
            placeholder="Enter Trip Id"
            value={searchQuery._id || ""}
            onChange={(e) => handleSearchQuery("_id", e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">From District</label>
          <input
            type="text"
            placeholder="District Name"
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => handleSearchQuery('fromDistrict', e.target.value)}
            value={searchQuery.fromDistrict || ''}
          />
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">To District</label>
          <input
            type="text"
            placeholder="District Name"
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => handleSearchQuery('toDistrict', e.target.value)}
            value={searchQuery.toDistrict || ''}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Created Date</label>
          <input
            type="date"
            value={searchQuery.createdAt || ""}
            onChange={(e) => handleSearchQuery("createdAt", e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Start Date</label>
          <input
            type="date"
            value={searchQuery.startDate || ""}
            onChange={(e) => handleSearchQuery("startDate", e.target.value)}
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
       

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Vehicle Type</label>
          <select
            value={searchQuery.vehicleType || ''}
            onChange={(e) => {
              handleSearchQuery('vehicleType', e.target.value)
              if (e.target.value === 'Private Car') {
                handleSearchQuery('vehicleSeats', '4')
              } else if (e.target.value === 'Micro') {
                handleSearchQuery('vehicleSeats', '7')
              } else if (e.target.value === 'CNG') {
                handleSearchQuery('vehicleSeats', '3')
              } else if (e.target.value === 'Sedan') {
                handleSearchQuery('vehicleSeats', '4')
              }
            }}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          >
            <option value="">All</option>
            <option value="Sedan">Sedan</option>
            <option value="Micro">Micro</option>
            <option value="Private Car">Private Car</option>
            <option value="CNG">CNG</option>
          </select>
        </div>
        <div className="flex flex-col space-y-1">
          <label className="block text-sm font-medium text-gray-700">Vehicle Seat</label>
          <select
            value={searchQuery.vehicleSeats || ''}
            onChange={(e) => handleSearchQuery('vehicleSeats', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          >
            {searchQuery.vehicleType === "Private Car" && <option value="4">4</option>}
            {searchQuery.vehicleType === "Micro" &&
              <>
                <option value="7">7</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </>
            }
            {searchQuery.vehicleType === "CNG" && <option value="3">3</option>}
            {searchQuery.vehicleType === "Sedan" && <option value="4">4</option>}
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Trip Status</label>
          <select
            value={searchQuery.tripStatus || ''}
            onChange={(e) => handleSearchQuery('tripStatus', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          >
            <option value="">All</option>
            {Object.values(tripStatus).map((status) => (
              <option key={status.key} value={status.key}>{status.value}</option>
            ))}
          </select>
        </div>
      </div>
      

      <div className="flex justify-end mt-4 space-x-3">
        <button
          className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
          onClick={clearSearch}
        >
          Clear ➡
        </button>
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={() => searchTrip()}
          onKeyDown={(e) => e.key === 'return' && searchTrip()}
        >
          Search
        </button>
      </div>
    </div>
  )
}

export default TripSearch;