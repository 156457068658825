import React from "react";

import dwonArrow from "../../assets/dwonArrow.png";

const OwnerTable = (props) => {
  return (
    <div className="flex flex-col mt-10 w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Verification Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Phone
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Address
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total vehicles
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    OTP
                  </th>

                  <th scope="col" className="relative px-3 py-3">
                    {/*<span className="sr-only">Edit</span>*/}
                    <h3 className="text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Edit
                    </h3>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {props.owners.map((item) => (
                  <tr key={item.mobile}>
                    <td className="px-3  flex py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item?.name}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div
                        className={`px-2 inline-flex text-sm leading-5 font-semibold rounded-full capitalize ${item?.isVerifiedAsOwner
                          ? "bg-green-100 text-green-800"
                          : "bg-red-400 text-white"
                          }`}
                      >
                        {item?.isVerifiedAsOwner ? "verified" : "Not verified"}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item?.mobile}
                      </div>
                      {/* <div className="text-sm text-gray-900">
                        {item?.mobile} ={item?._id}
                      </div> */}
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {item?.address?.city}
                      </div>
                      <div className="text-sm text-gray-900">
                        {item?.address?.subDistrict}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap  text-center text-sm font-medium">
                      <div className="text-sm text-gray-900">
                        {item?.vehicles?.length > 0
                          ? item?.vehicles?.length
                          : "N/A"}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap  text-center text-sm font-medium">
                      <div className="text-sm text-gray-900">
                        {item?.otp ? item?.otp : "N/A"}
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-nowrap text-center text-sm font-medium">
                      <button
                        onClick={() => props.ShowOwnerSecthom(item)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150"
                      >
                        <svg className="h-4 w-4 mr-1.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerTable;
