import React, {useEffect, useState} from "react";
import Nav from '../../components/nav/Nav';
import NavItem from '../../components/nav/NavItem';
import List from '../../components/List';
import OwnerItem from './OwnerItem';
import AdminService from '../../services/admin/AdminService'
import {Header} from '../../components'

function OwnerList(props) {
  const [owners, setOwners] = useState([]);

  useEffect(() => {

    AdminService.getOwners().then(data => {
      setOwners(data.data);
    })
  }, [])

  return (
    <div className="divide-y divide-gray-100">
      {/*<Nav>*/}
      {/*  <NavItem href="/featured" isActive>Featured</NavItem>*/}
      {/*  <NavItem href="/popular">Popular</NavItem>*/}
      {/*  <NavItem href="/recent">Recent</NavItem>*/}
      {/*</Nav>*/}
      <Header/>
      <List>
        {owners.map((owner) => (
          <OwnerItem
            // key={recipe.id}
            owner={owner}/>
        ))}
      </List>
    </div>
  )

}



export default OwnerList;
