import React, {useState, useEffect} from "react"
import LocationService from '../../services/owner/LocationService';

const UpdareSubDistrict = (props) => {
  const [name, setName] = useState(props.selectedLocation.name || '');
  const [latitude, setLatitude] = useState(props.selectedLocation.latitude || '');
  const [longitude, setLongitude] = useState(props.selectedLocation.longitude || '');

  const [subDistrictId, setSubDistrictId] = useState('');
  const [subDistrictname, setSubDistrictName] = useState('');
  const [subDistrictLatitude, setSubDistrictLatitude] = useState('');
  const [subDistrictLongitude, setSubDistrictLongitude] = useState('');
  const [isUpdateSubDistrict, setUpdateSubDistrictStatus] = useState(false);
  const [isSubDistrictShowPan, setUpdateSubDistrictShowPan] = useState(false);


  const updateLocation = () => {
    //    updateLocation
    const payload = {
      name,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    }
    props.updateLocation(props.selectedLocation._id, payload);
    props.setUpdatePanelStatus(!props.showUpdatePanel);

  }

  const updateAddSubLocation = () => {
    if(isUpdateSubDistrict){
    //  TODO
    }else {
      const payload = {
        name: subDistrictname,
        type: "subDistrict",
        latitude: parseFloat(subDistrictLatitude),
        longitude: parseFloat(subDistrictLongitude),
      }
      LocationService.addSubLocation(props.selectedLocation._id,payload).then(data=>{
        props.fetchLocation()
      })
    }
  }
  return (
    <div className={"bg-white w-80 shadow-xl rounded p-5 mt-10 mr-6"}>
      <div
        className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800"></p>
        <svg
          onClick={() => props.setUpdatePanelStatus(!props.showUpdatePanel)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>

      <form className="space-y-5 mt-5">
        <input
          type="text"
          value={name}
          onChange={(e) => {
            setName(e.target.value)
          }}
          className="  w-64 h-12 border border-gray-800 rounded px-3"
          placeholder="Dhaka"
        />
        <input
          type="text"
          value={latitude}
          onChange={(e) => {
            setLatitude(e.target.value)
          }}
          className=" w-64 h-12 border border-gray-800 rounded px-3"
          placeholder="23.811056"
        />
        <input
          type="text"
          value={longitude}
          onChange={(e) => {
            setLongitude(e.target.value)
          }}
          className=" w-64 h-12 border border-gray-800 rounded px-3"
          placeholder="90.407608"
        />

        <button
          onClick={updateLocation}
          className=" w-64  mt-10 bg-indigo-800 px-5 py-3 text-sm shadow-sm font-medium tracking-wider border text-indigo-100 rounded-full hover:shadow-lg hover:bg-indigo-900">Update
        </button>

        {/*<h3 className="px-6 mt-12  my-2 text-sm font-medium text-gray-900" >Cars </h3>*/}

        <div className="care_items p-1  bg-indigo-100 w-full">
          <tr className="flex">

            <div className="flex-auto">
              <td>
                <div className=" ml-1 text-sm font-medium text-gray-900">Sub District</div>

              </td>
            </div>

            <td>
              <a onClick={() => {
                setUpdateSubDistrictShowPan(!isSubDistrictShowPan);
                setUpdateSubDistrictStatus(false)
                setSubDistrictName('')
                setSubDistrictLatitude('')
                setSubDistrictLongitude('')
                setSubDistrictId('')
              }} href="#"
                 className="text-indigo-600 hover:text-indigo-900">
                Add
              </a>
            </td>

          </tr>
        </div>

        {isSubDistrictShowPan &&
        <div className="space-y-5 mt-5">
          <input
            type="text"
            value={subDistrictname}
            onChange={(e) => {
              setSubDistrictName(e.target.value)
            }}
            className="  w-64 h-12 border border-gray-800 rounded px-3"
            placeholder="Dhaka"
          />
          <input
            type="text"
            value={subDistrictLatitude}
            onChange={(e) => {
              setSubDistrictLatitude(e.target.value)
            }}
            className=" w-64 h-12 border border-gray-800 rounded px-3"
            placeholder="23.811056"
          />
          <input
            type="text"
            value={subDistrictLongitude}
            onChange={(e) => {
              setSubDistrictLongitude(e.target.value)
            }}
            className=" w-64 h-12 border border-gray-800 rounded px-3"
            placeholder="90.407608"
          />

          <button
            onClick={updateAddSubLocation}
            className=" w-64  mt-10 bg-indigo-800 px-5 py-3 text-sm shadow-sm font-medium tracking-wider border text-indigo-100 rounded-full hover:shadow-lg hover:bg-indigo-900">
            {isUpdateSubDistrict?'Update':'Add'}
          </button>
        </div>}

        <div className="mb-12">
          {
            props.selectedLocation.subDistricts.map((item) => {
              return (
                <div className="care_items p-1   bg-indigo-100 w-full">
                  <tr className="flex justify-center justify-items-center content-center ">
                    <td className="flex-1 ml-1">
                      <div className="  text-sm font-medium text-gray-900"> {item.name}</div>

                    </td>
                    <td className="mr-4 ">
                      <h4 className=" ">Delete</h4>

                    </td>

                    <td className=" ">
                      <a href="#"
                         onClick={()=>{
                           setUpdateSubDistrictShowPan(true);
                           setUpdateSubDistrictStatus(true);
                           setSubDistrictName(item.name)
                           setSubDistrictLatitude(item.latitude)
                           setSubDistrictLongitude(item.longitude)
                           setSubDistrictId(item._id)
                         }}
                         className=" mr-4 text-sm font-medium text-indigo-600 hover:text-indigo-900">
                        Edit
                      </a>
                    </td>

                  </tr>
                </div>


              )

            })
          }
        </div>

      </form>
    </div>
  )
};
export default UpdareSubDistrict;
