import React, { useState } from "react";
import ImageS3 from "../common/ImageS3";
import AuthService from "../../services/owner/AuthService";
import useAuthentication from "../HOC/useAuthentication";
import Conformation from "../common/conformation";

const PassengerTable = ({ owners, tableFor, isedit, handleModal, refresh }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedRow, setExpandedRow] = useState(null);
  const { user, setUser } = useAuthentication();
  const [visibleConformation, setVisibleConformation] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  console.log('Owners are', owners)


  const getName = (driveinfo) => {
    if (driveinfo?.name) return driveinfo.name;
    if (driveinfo?.myOwners?.length > 0) return driveinfo.myOwners[0].name;
    return "N/A";
  };

  const isVerified = (driveinfo) => {
    if (tableFor === "driver") return driveinfo?.isVerifiedAsDriver;
    if (tableFor === "passenger") return driveinfo?.isVerified;
    return false;
  };

  // Update the toggleRow function
  const toggleRow = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const handleVerification = (driver) => {
    setSelectedDriver(driver);
    setVisibleConformation(true);
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden  mt-4">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {["Name", "Phone", "Address", "Driving Car", "OTP", "Edit", "Trip History"].map((header) => (
                <th key={header} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {owners.map((item) => (
              <React.Fragment key={item._id}>
                <tr
                  className={`hover:bg-gray-50 cursor-pointer ${expandedRow === item._id ? 'bg-blue-50' : ''}`}
                  onClick={() => toggleRow(item._id)}
                >
                  <td className="px-6 py-4  capitalize">
                    <div className="text-sm font-medium text-gray-900 flex flex-row gap-2 items-center">
                      <p className="font-bold">{getName(item)}</p>
                    </div>
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${isVerified(item) ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                      }`}>
                      {isVerified(item) ? "Verified" : "Not Verified"}
                    </span>
                  </td> */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{item?.mobile || "N/A"}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {item?.address?.city && item?.address?.subDistrict
                        ? `${item.address.city}, ${item.address.subDistrict}`
                        : "N/A"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {item?.assignTrip?.vehicleDetails?.model || "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {item?.otp || "N/A"}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    {isedit && (
                      <button
                        onClick={(e) => {
                          toggleRow(item._id);
                        }}
                        // className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                        Edit
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    {isedit && (
                      <button
                        onClick={() => handleModal(true, item)}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      >
                        <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        View
                      </button>
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PassengerTable;