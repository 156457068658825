export const Pagination = ({ page, totalPages, setPage, limit, setLimit, prevPage, nextPage }) => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const limitOptions = [10, 15, 25, 50, 100];

    let startPage = Math.max(1, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    // Adjust start page if we're near the end
    if (endPage - startPage + 1 < maxVisiblePages) {
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="flex items-center space-x-4">
            <button
                onClick={prevPage}
                disabled={page === 1}
                className={`px-5 py-2 rounded-lg text-white font-semibold transition-all duration-300 ${page === 1 ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600 active:scale-95"
                    }`}
            >
                ⬅ Previous
            </button>

            <div className="flex items-center space-x-2">
                {startPage > 1 && (
                    <>
                        <button
                            onClick={() => setPage(1)}
                            className="px-3 py-1 rounded-md text-sm bg-gray-200 hover:bg-gray-300"
                        >
                            1
                        </button>
                        {startPage > 2 && <span className="text-gray-500">...</span>}
                    </>
                )}

                {pageNumbers.map(number => (
                    <button
                        key={number}
                        onClick={() => setPage(number)}
                        className={`px-3 py-1 rounded-md text-sm ${page === number
                            ? 'bg-green-500 text-white'
                            : 'bg-gray-200 hover:bg-gray-300'
                            }`}
                    >
                        {number}
                    </button>
                ))}

                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && <span className="text-gray-500">...</span>}
                        <button
                            onClick={() => setPage(totalPages)}
                            className="px-3 py-1 rounded-md text-sm bg-gray-200 hover:bg-gray-300"
                        >
                            {totalPages}
                        </button>
                    </>
                )}
            </div>

            <button
                onClick={nextPage}
                disabled={page === totalPages}
                className={`px-5 py-2 rounded-lg text-white font-semibold transition-all duration-300 ${page === totalPages ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600 active:scale-95"
                    }`}
            >
                Next ➡
            </button>

            <select
                value={limit}
                onChange={(e) => {
                    setLimit(Number(e.target.value));
                    setPage(1); // Reset to first page when limit changes
                }}
                className="px-3 py-2 rounded-lg text-sm font-medium border border-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent shadow-sm transition-all duration-200 cursor-pointer appearance-none pr-8 pl-3"
                style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'right 0.5rem center',
                    backgroundSize: '1.5em 1.5em'
                }}
            >
                {limitOptions.map(opt => (
                    <option key={opt} value={opt} className="py-1">
                        {opt} per page
                    </option>
                ))}
            </select>
        </div>
    );
};
