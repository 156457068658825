import React, { useState, useEffect } from "react";
import VehicleService from "../../services/owner/VehicleService";
import "./index.css";
import ImageS3 from "../../components/common/ImageS3";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import FileService from "../../services/FileService";

const CarInfo = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const [s3PubicUri, setS3PubicUri] = useState(null);

  console.log("verifiedStatus === ", props.carInfo.vehicle.verifiedStatus);
  console.log("props.carInfo.vehicle === ", props.carInfo.vehicle._id);

  const verifyVehicle = async () => {
    const vehicle = props.carInfo.vehicle;
    const body = {};
    if (vehicle?.verifiedStatus === "pending") {
      body.verifiedStatus = "verified";
    } else if (vehicle?.verifiedStatus === "verified") {
      body.verifiedStatus = "pending";
    } else {
      return;
    }
    // alert(JSON.stringify(body));

    await VehicleService.verifyVehicle(vehicle?._id, body);

    props?.handleModal(false);
    window.location.reload();
  };

  const CarPhoto = ({ title, uri }) => {
    if (!uri) return null;
    return (
      <div className="mr-2">
        {title && <p className="text-sm font-semibold h-10">{title}</p>}

        <ImageS3 uri={uri} style={"w-40 h-28 rounded-lg"} />
      </div>
    );
  };

  console.log("carinfoooo:", props?.carInfo?.vehicle?.docs);

  return (
    <div>
      <div className="">
        <div className="flex items-center justify-center">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
            {/*card 1 */}
            <div className=" bg-white rounded-lg w-64 my-4 shadow-xl p-2">
              <div className="mt-2">
                <p className="text-xl font-semibold my-2">Car info</p>
                <div className="border-t-2"></div>
                <div className="flex space-x-2 text-gray-400 text-sm">
                  <p>Name :</p>
                  <p>{props.carInfo?.vehicle?.carInfo.model}</p>
                </div>
                <div className="flex space-x-2 text-gray-400 text-sm">
                  <p>Seats :</p>
                  <p>{props.carInfo?.vehicle?.seats}</p>
                </div>

                <div className="flex space-x-2 text-gray-400 text-sm">
                  <p>No Plate : </p>
                  <p>
                    {props.carInfo.vehicle.registration.cityName +
                      " " +
                      props.carInfo.vehicle.registration.vehicleCategory +
                      " - " +
                      props.carInfo?.vehicle?.registration?.vehicleNumber}
                  </p>
                </div>
                <div className="flex space-x-2 text-gray-400 text-sm">
                  <p>Status : </p>
                  {props.carInfo.vehicle.verifiedStatus === "pending" ? (
                    <h5>Not Varify </h5>
                  ) : (
                    <h5>verified</h5>
                  )}
                </div>
                <button
                  onClick={verifyVehicle}
                  className={`mt-1 text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 
                    ${props.carInfo.vehicle.verifiedStatus === "pending"
                      ? 'bg-green-600 hover:bg-green-700'
                      : 'bg-red-600 hover:bg-red-700'
                    }`}
                >
                  {props.carInfo.vehicle.verifiedStatus === "pending" ? (
                    <h5>Verify</h5>
                  ) : (
                    <h5>Remove Verification</h5>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>

      </div>
      <div className=" flex">
        <div className="flex flex-col">
          <CarPhoto
            title={props?.carInfo?.vehicle?.docs?.vehicleFont.title}
            uri={props?.carInfo?.vehicle?.docs?.vehicleFont.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            title={props?.carInfo?.vehicle?.docs?.vehicleBack.title}
            uri={props?.carInfo?.vehicle?.docs?.vehicleBack.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            title={props?.carInfo?.vehicle?.docs?.vehicleNumberPlate?.title}
            uri={props?.carInfo?.vehicle?.docs?.vehicleNumberPlate?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            title={props?.carInfo?.vehicle?.docs?.smartCard?.title}
            uri={props?.carInfo?.vehicle?.docs?.smartCard?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            title={props?.carInfo?.vehicle?.docs?.taxToken?.title}
            uri={props?.carInfo?.vehicle?.docs?.taxToken?.uri}
          />
        </div>
        <div className="flex flex-col">
          <CarPhoto
            title={props?.carInfo?.vehicle?.docs?.fitness?.title}
            uri={props?.carInfo?.vehicle?.docs?.fitness?.uri}
          />
        </div>
      </div>
    </div>
  );
};
export default CarInfo;
