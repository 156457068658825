import http from './http';
import {API_BASE_URL} from '../config';

export default class BookingService {
  static getBookingData = () =>
    http
      .get(`${API_BASE_URL}/booking`)
      .then((res) => res.data);

  static updateBookingData = (payload) => {
    console.log(payload)
   return  http.post(`${API_BASE_URL}/booking`, payload).then((res) => res.data);
  }

}
