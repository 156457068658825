// import React, { useState, useContext, useEffect } from "react"
// import Conformation from '../../components/common/conformation';
// import AuthService from '../../services/owner/AuthService';
// import AuthContext from '../../shared/AuthContext';
// import "../../components/Tables/index.css"
// import useAuthentication from '../../components/HOC/useAuthentication';
// import FileService from '../../services/FileService';
// import ImageS3 from "../../components/common/ImageS3";

// const OwnerInfomation = (props) => {

//   const [visibleConformation, setVisibilityConformation] = useState(false);
//   const { user, setUser } = useAuthentication();
//   const [profileIcon, setProfileIcon] = useState();

//   return (
//     <div className={"bg-white w-96 shadow-xl rounded p-5 mt-10 mr-6 ml-5 "}>
//       {visibleConformation &&
//         <Conformation
//           title={'Verify Driver'}
//           msg={'Are you sure to verify'}
//           confirm={() => {
//             const adminId = localStorage.getItem('userId');
//             AuthService.verifyOwner(user?.user?._id, props?.driveinfo?._id).then(data => {
//               if (props.refresh) {
//                 props.setshowdriveinfo(!props.showdriveinfo);
//                 props.refresh();
//               }
//               // alert(data)
//             })
//           }}
//         />}

//       <div
//         className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg"
//       >
//         <p className="font-semibold text-gray-800"></p>
//         <svg
//           onClick={() => props.setshowdriveinfo(!props.showdriveinfo)}
//           className="w-6 h-6 cursor-pointer "
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             stroke-linecap="round"
//             stroke-linejoin="round"
//             stroke-width="2"
//             d="M6 18L18 6M6 6l12 12"
//           ></path>
//         </svg>
//       </div>
//       <div>
//         <div>
//           <div className="dashboard_profile  pt-6">
//             {/* <img className="  h-24 w-24  rounded-full"
//               src="https://scontent.fcgp17-1.fna.fbcdn.net/v/t1.6435-9/79776706_1077879655892660_6715394724091396096_n.jpg?_nc_cat=103&ccb=1-3&_nc_sid=174925&_nc_ohc=IAb23VM4F7sAX9R5RNa&_nc_ht=scontent.fcgp17-1.fna&oh=26ac73c356fafa137451b77fc20a1697&oe=60D5C384" /> */}

//               <ImageS3 uri={props.driveinfo?.profile} style={'h-24 w-24  rounded-full'} />
//               {/* {JSON.stringify(props.driveinfo?.profile)} */}

//           </div>
//           <div className="mt-12">
//             <tr>
//               <td className=" ">
//                 <div className="text-sm font-medium text-gray-900">Name :</div>
//               </td>
//               <td>
//                 <div
//                   className="ml-2  text-sm font-medium text-gray-900">{props?.driveinfo?.name}{props?.driveinfo?.firstName} {props?.driveinfo?.lastName}</div>

//               </td>

//             </tr>
//             <tr>
//               <td className=" ">
//                 <div className="text-sm font-medium text-gray-900">Phone :</div>
//               </td>
//               <td>
//                 <div className="ml-2  text-sm font-medium text-gray-900">{props?.driveinfo?.mobile}</div>
//               </td>
//             </tr>
//             <tr>
//               <td className="">
//                 <div className=" text-sm font-medium text-gray-900">City :</div>
//               </td>
//               <td>
//                 <div className="ml-2 text-sm font-medium text-gray-900">{props?.driveinfo?.address?.city}</div>
//               </td>
//             </tr>
//             <tr>
//               <td className="">
//                 <div className="text-sm font-medium text-gray-900">Status :</div>
//               </td>
//               <td>
//                 {
//                   props?.driveinfo?.isVerifiedAsOwner ?
//                     <div className="ml-2 text-sm font-medium text-gray-900">verify</div> :
//                     <div className="ml-2 text-sm font-medium text-gray-900">Not verify</div>
//                 }

//               </td>
//             </tr>

//             {/*<button className=''>Varify Driver</button>*/}

//             <button
//               onClick={() => setVisibilityConformation(!visibleConformation)}
//               style={{ backgroundColor: '#1DEC4B' }}
//               className=" ml-10 mt-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
//               {props.driveinfo.isVerifiedAsOwner ? <h5>Remove Verification</h5> : <h5>Varify</h5>}

//             </button>

//           </div>

//         </div>

//       </div>

//       {props?.driveinfo?.vehicles?.length > 0 &&
//         <div>
//           <h3 className=" mt-5  my-2 text-sm font-medium text-gray-900">Cars</h3>
//           <div className="mb-12">
//             {
//               props?.driveinfo?.vehicles?.map((item) => {
//                 return (
//                   <div className="care_items p-1 bg-indigo-100 ">
//                     <tr className="flex">

//                       <div className="flex-auto mt-1">
//                         <td>
//                           {item?.vehicle?.verifiedStatus === 'verified' &&
//                             <h2 className="dote_round mr-2"></h2>}

//                         </td>
//                         <td>
//                           <p className=" text-sm font-medium text-gray-900 ">{item?.vehicle?.registration?.cityName +
//                             " " +
//                             item?.vehicle?.registration?.vehicleCategory +
//                             " - " +
//                             item?.vehicle?.registration?.vehicleNumber}</p>

//                         </td>

//                       </div>

//                       <td className="px-6 ">
//                         <a
//                           onClick={() => props.showVihicleInfo(item)}
//                           className="  text-sm font-medium text-indigo-600 hover:text-indigo-900">
//                           Manage
//                         </a>
//                       </td>

//                     </tr>
//                   </div>

//                 )

//               })
//             }
//           </div>

//         </div>
//       }

//     </div>

//   )
// };

// export default OwnerInfomation;

import React, { useState, useContext, useEffect } from "react";
import Conformation from "../../components/common/conformation";
import AuthService from "../../services/owner/AuthService";
import AuthContext from "../../shared/AuthContext";
import "../../components/Tables/index.css";
import useAuthentication from "../../components/HOC/useAuthentication";
import FileService from "../../services/FileService";
import ImageS3 from "../../components/common/ImageS3";

const OwnerInfomation = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);
  const { user, setUser } = useAuthentication();
  const [profileIcon, setProfileIcon] = useState();

  console.log("props=>", props.ownerInfo)

  return (
    <div className={"bg-white w-96 shadow-xl rounded p-5 mt-10 mr-6 ml-5 "}>
      {visibleConformation && (
        <Conformation
          title={"Verify Owner"}
          msg={"Are you sure to verify"}
          confirm={() => {
            const adminId = localStorage.getItem("userId");
            AuthService.verifyOwner(
              user?.user?._id,
              props?.ownerInfo?._id
            ).then((data) => {
              if (props.refresh) {
                props.setShowOwnerInfo(!props.showOwnerInfo);
                props.refresh();
              }
              // alert(data)
            });
          }}
        />
      )}

      <div className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg">
        <p className="font-semibold text-gray-800"></p>
        <svg
          onClick={() => props.setShowOwnerInfo(!props.showOwnerInfo)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
      <div>
        <div>
          <div className="dashboard_profile  pt-6">
            {/* <img className="  h-24 w-24  rounded-full"
              src="https://scontent.fcgp17-1.fna.fbcdn.net/v/t1.6435-9/79776706_1077879655892660_6715394724091396096_n.jpg?_nc_cat=103&ccb=1-3&_nc_sid=174925&_nc_ohc=IAb23VM4F7sAX9R5RNa&_nc_ht=scontent.fcgp17-1.fna&oh=26ac73c356fafa137451b77fc20a1697&oe=60D5C384" /> */}

            <ImageS3
              uri={props.ownerInfo?.profile}
              style={"h-24 w-24 rounded-full"}
            />
            {/* {JSON.stringify(props.driveinfo?.profile)} */}
          </div>
          <div className="mt-12">
            <tr className="flex">
              <td className=" ">
                <div className="text-sm font-medium text-gray-900">Name:</div>
              </td>
              <td>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  <h6>
                    {props?.ownerInfo?.name}
                    {(props?.ownerInfo?.firstName || props?.ownerInfo?.lastName) && (
                      <span>
                        {' '}({props?.ownerInfo?.firstName || ''} {props?.ownerInfo?.lastName || ''})
                      </span>
                    )}
                  </h6>
                </div>
              </td>
            </tr>
            <tr>
              <td className=" ">
                <div className="text-sm font-medium text-gray-900">Phone:</div>
              </td>
              <td>
                <div className="ml-2  text-sm font-medium text-gray-900">
                  {props?.ownerInfo?.mobile}
                </div>
              </td>
            </tr>
            <tr>
              <td className="">
                <div className=" text-sm font-medium text-gray-900">City:</div>
              </td>
              <td>
                <div className="ml-2 text-sm font-medium text-gray-900">
                  {props?.ownerInfo?.address?.city}
                </div>
              </td>
            </tr>
            <tr>
              <td className="">
                <div className="text-sm font-medium text-gray-900">
                  Status:
                </div>
              </td>
              <td>
                {props?.ownerInfo?.isVerifiedAsOwner ? (
                  <div className="ml-2 text-sm font-medium text-gray-900">
                    Verified
                  </div>
                ) : (
                  <div className="ml-2 text-sm font-medium text-gray-900">
                    Not Verified
                  </div>
                )}
              </td>
            </tr>

            {/*<button className=''>Varify Driver</button>*/}

            <button
              onClick={() => setVisibilityConformation(!visibleConformation)}
              className={`mt-1 text-white font-medium rounded-lg text-sm px-5 py-2.5 me-2 
                ${props.ownerInfo.isVerifiedAsOwner
                  ? 'bg-red-600 hover:bg-red-700'
                  : 'bg-green-600 hover:bg-green-700'
                }`}
            >
              {props.ownerInfo.isVerifiedAsOwner ? (
                <h5>Remove Verification</h5>
              ) : (
                <h5>Verify</h5>
              )}
            </button>
          </div>
        </div>
      </div>

      {props?.ownerInfo?.vehicles?.length > 0 && (
        <div>
          <h3 className=" mt-5  my-2 text-sm font-medium text-gray-900">
            Cars
          </h3>
          <div className="mb-12">
            {props?.ownerInfo?.vehicles?.map((item) => {
              return (
                <div key={item?._id} className="care_items p-1">
                  <tr className="flex  bg-indigo-100 mb-1, p-1 rounded-lg">
                    <div className="flex-auto content-center">
                      <td>
                        {item?.vehicle?.verifiedStatus === "verified" && (
                          <h2 className="dote_round mr-2"></h2>
                        )}
                      </td>
                      <td>
                        <p className=" text-sm font-medium text-gray-900 ">
                          {item?.vehicle?.registration?.cityName +
                            " " +
                            item?.vehicle?.registration?.vehicleCategory +
                            " - " +
                            item?.vehicle?.registration?.vehicleNumber}
                        </p>
                      </td>
                    </div>

                    <td className="">
                      <button onClick={() => props.showVihicleInfo(item)} type="button" className="text-white bg-green-600 hover:bg-green-700 font-medium rounded-lg text-sm px-5 py-2.5 me-2">
                        Manage
                      </button>
                    </td>
                  </tr>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnerInfomation;
