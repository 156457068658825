import React, { useState } from "react";
import { Button } from "react-chat-elements";
import ReactModal from "react-modal";
import AdminService from "../../services/admin/AdminService";
import moment from "moment";
import ImageS3 from "../../components/common/ImageS3";
import BidTableInfo from "../../components/Tables/bid_table_info";

export default function InitiatedTrip(props) {
  const [open, setOpen] = useState(false);
  const [bidInfo, setBidInfo] = useState();
  const [openIndex, setOpenIndex] = useState();

  console.log("data in InitiatedTrip==>", props?.data);

  const bids = async (id) => {
    const data = await AdminService.bids(id);
    setBidInfo(data.data.bids);
    setOpen(!open);
  };

  console.log("bidInfo", bidInfo);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Trip Id</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">From</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">To</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Time</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bid Count</th>
                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {props?.data && [...props.data].reverse().map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-900">{item._id}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.fromLocation.name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.toLocation.name}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.startDate.substr(0, 10)}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.startTime}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">{item.bidCount}</td>
                  <td className="px-4 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => bids(item._id)}
                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline"
                    >
                      View Bids
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ReactModal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={{
          content: {
            border: 'none',
          }
        }}
      >
        <div className=" rounded-lg shadow-xl w-full mx-auto bg-blue-100">
          <div className="flex justify-between items-center p-6 border-b">
            <h2 className="text-2xl font-bold text-gray-900">Bid Information Here</h2>
            <button onClick={() => setOpen(false)} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">Close</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="p-6 overflow-x-auto">
            <BidTableInfo
              bidInfo={bidInfo}
            />
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
