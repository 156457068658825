import React from "react";
import Layout from "../Layout/index";
import { useState, useEffect } from "react";
import DriverTable from "../Tables/driver_table";
import DriverInfo from "../Tables/driver_manage_info";
import Conformation from "../common/conformation";

import DriverInfomation from "./driverInfo";
import CarInfoModel from "../common/car_info_model";
import { sideBarItems } from "../../utils";
import _ from "lodash";
import PassengerService from "../../services/PassengerService";
import PassTable from "../Tables/bid_table_pass";
import PassengerModel from "../../components/common/passengers_bid_model";

const DriverList = () => {
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [showdriveinfo, setshowdriveinfo] = useState(false);
  const [passengerInfo, setPassengerInfo] = useState();
  const [isedit, setIsedit] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);

  const ShowDriveSecthom = (item) => {
    setshowdriveinfo(true);
    setPassengerInfo(item);
  };

  useEffect(() => {
    initApi();
  }, []);

  const initApi = () => {
    PassengerService.getPassenger().then((data) => {
      setOwners(data);
      setFilteredOwners(data);
    });
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredOwners = _.filter(owners, (item) => {
      return (
        item.mobile.match(new RegExp(searchQuery, "gi")) ||
        item?.name?.match(new RegExp(searchQuery, "gi")) ||
        item._id.match(new RegExp(searchQuery, "gi"))
      );
    });
    setFilteredOwners(filteredOwners);
  };

  const handleModal = (isVisible) => {
    setVisibleModal(isVisible);
  };

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.passenger.key}
      modal={
        <PassengerModel
          handleModal={handleModal}
          passengerInfo={passengerInfo}

          // carInfo={carInfo}
        />
      }
      visibleModal={visibleModal}
      handleModal={handleModal}
    >
      {/*<Conformation/>*/}
      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <DriverTable
            tableFor="passenger"
            owners={filteredOwners}
            isedit={isedit}
            ShowDriveSecthom={ShowDriveSecthom}
            handleModal={(isVisible, item) => {
              handleModal(isVisible);
              setPassengerInfo(item);
            }}
          />
        </div>
        {showdriveinfo && (
          <DriverInfomation
            tableFor="passenger"
            driveinfo={passengerInfo}
            setshowdriveinfo={setshowdriveinfo}
            refresh={initApi}
            showdriveinfo={showdriveinfo}
          />
        )}
      </div>
    </Layout>
  );
};
export default DriverList;
