import React, { useState, useEffect } from "react";
import "./index.css";
import BidTable from "../../components/Tables/bid_table";
import _ from "lodash";
import TripService from "../../services/TripService";

const PassBidInfo = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);

  const [trips, setTrips] = useState([]);
  const [isedit, setIsedit] = useState(true);

  useEffect(() => {
    init().catch();
  }, [props?.passengerInfo]);

  const init = async () => {
    const res = await TripService.getTrips({passengerId:props?.passengerInfo?._id});
    setTrips((_.isArray(res?.data) && res?.data) || []);
  };

  const verifyVehicle = async () => {
    props?.handleModal(true);
    window.location.reload();
  };

  return (
    <div>
      <div>
        <BidTable
          trips={trips}
          isedit={isedit}
          //   ShowDriveSecthom={ShowDriveSecthom}
        />
      </div>
    </div>
  );
};
export default PassBidInfo;
