import React, { useState } from "react";
import moment from 'moment';
import ImageS3 from "../common/ImageS3";

const TripTable = ({ trips }) => {
  const [openDetailId, setOpenDetailId] = useState(null);

  console.log("trips", trips)

  const tableHeaders = [
    "Trip ID",
    "From",
    "To",
    "Car Type",
    "Date",
    "Trip Status",
    "Actions"
  ];

  const toggleDetails = (id) => {
    setOpenDetailId(openDetailId === id ? null : id);
  };

  const TripDetails = ({ trip }) => (
    <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <h4 className="font-semibold text-lg text-indigo-600 mb-3">Passenger Details</h4>
          <div className="flex items-center mb-2">
            {/* <img src={trip.passenger.profilePicture} alt={trip.passenger.name} className="w-10 h-10 rounded-full mr-3" /> */}
            <ImageS3
              uri={trip?.passenger?.profilePicture}
              style={"h-16 w-16 rounded-full"}
              // className="w-10 h-10 rounded-full mr-3"
            />
            <div className="ml-3">
              <p className="font-medium">{trip?.passenger?.name}</p>
              <p className="text-sm text-gray-600">{trip?.passenger?.mobile}</p>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <h4 className="font-semibold text-lg text-indigo-600 mb-3">Trip Details</h4>
          <p><span className="font-medium">From:</span> {trip.fromLocation.name} ({trip.fromLocation.district})</p>
          <p><span className="font-medium">To:</span> {trip.toLocation.name} ({trip.toLocation.district})</p>
          <p><span className="font-medium">Vehicle:</span> {trip.vehicleType}</p>
          <p><span className="font-medium">Date:</span> {moment(trip.startDate).format('ll')}</p>
          <p><span className="font-medium">Time:</span> {trip.startTime}</p>
          <p><span className="font-medium">Trip Type:</span> {trip.tripType}</p>
          <p><span className="font-medium">Status:</span> <span className={`capitalize font-semibold ${trip.tripStatus === 'completed' ? 'text-green-600' :
            trip.tripStatus === 'canceled' ? 'text-red-600' :
              'text-yellow-600'
            }`}>{trip.tripStatus}</span></p>
          {trip.startCode && <p className="bg-yellow-100"><span className="font-medium">Start Code:</span> {trip.startCode}</p>}
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <h4 className="font-semibold text-lg text-indigo-600 mb-3">Fare Details</h4>
          <p><span className="font-medium">Trip Fare:</span> ৳{trip?.fare?.amount}</p>
          {trip?.fare?.fare_round_trip > 0 && <p><span className="font-medium">Round Trip Fare:</span> ৳{trip?.fare?.fare_round_trip}</p>}
          {trip?.fare?.fare_u_turn_trip > 0 && <p><span className="font-medium">U-Turn Trip Fare:</span> ৳{trip?.fare?.fare_u_turn_trip}</p>}
          <p><span className="font-medium">Driver Booking:</span> {trip?.booking?.driver?.bookingType} (৳{trip?.booking?.driver?.bookingValue})</p>
          <p><span className="font-medium">Passenger Booking:</span> {trip?.booking?.passenger?.bookingType} (৳{trip?.booking?.passenger?.bookingValue})</p>
        </div>

        {trip.acceptedBid && trip.acceptedBid.bidAmount > 0 && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-indigo-600 mb-3">Bid Details</h4>
            <p><span className="font-medium">Bid Count:</span> {trip.bidCount}</p>
            <p><span className="font-medium">Accepted Bid Amount:</span> ৳{trip.acceptedBid.bidAmount}</p>
            <p><span className="font-medium">Driver Amount:</span> ৳{trip.acceptedBid.driverAmount}</p>
            {trip.driver && (
              <div className="mt-2">
                <p><span className="font-medium">Driver Name:</span> {trip.driver.name}</p>
                <p><span className="font-medium">Driver Mobile:</span> {trip.driver.mobile}</p>
              </div>
            )}
          </div>
        )}

        {trip.tripStatus === 'canceled' && trip.canceledBy && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-red-600 mb-3">Cancellation Details</h4>
            <p><span className="font-medium">Canceled On:</span> {moment(trip.canceledBy.cancellationDate).format('lll')}</p>
            <p><span className="font-medium">Canceled By:</span> {
              trip.canceledBy.passenger ? 'Passenger' :
                trip.canceledBy.driver ? 'Driver' :
                  'System (Auto)'
            }</p>
            <p><span className="font-medium">Cancel Type:</span> {trip.canceledBy.cancelType || 'N/A'}</p>
            <p><span className="font-medium">Refunded:</span> {trip.canceledBy.isRefunded ? 'Yes' : 'No'}</p>
          </div>
        )}

        {trip.log && trip.log.length > 0 && (
          <div className="bg-white p-4 rounded-lg shadow">
            <h4 className="font-semibold text-lg text-indigo-600 mb-3">Trip Log</h4>
            {trip.log.map((logEntry, index) => (
              <div key={logEntry._id} className="mb-2">
                <p><span className="font-medium">Action:</span> {logEntry.title}</p>
                <p><span className="font-medium">By:</span> {logEntry.role}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="mt-10 w-full overflow-hidden shadow-lg rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {tableHeaders.map((header, index) => (
              <th
                key={index}
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {[...trips].reverse().map((trip, index) => (
            <React.Fragment key={trip._id + index}>
              <tr className="hover:bg-gray-50 transition-colors duration-200">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {trip._id}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {trip.fromLocation?.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {trip.toLocation?.name}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {trip.vehicleType}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {moment(trip.startDate).format('ll')} {trip.startTime}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 capitalize inline-flex text-xs leading-5 font-semibold rounded-full ${trip.tripStatus === 'completed' ? 'bg-green-100 text-green-800' :
                    trip.tripStatus === 'canceled' ? 'bg-red-100 text-red-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                    {trip.tripStatus}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <button
                    onClick={() => toggleDetails(trip._id)}
                    className="text-indigo-600 hover:text-indigo-900"
                  >
                    {openDetailId === trip._id ? 'Hide Details' : 'View Details'}
                  </button>
                </td>
              </tr>
              {openDetailId === trip._id && (
                <tr>
                  <td colSpan="7">
                    <TripDetails key={index} trip={trip} />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TripTable;