import React, { useEffect, useState } from "react";
import AdminService from "../../services/admin/AdminService";
import { Header } from "../../components";
import Layout from "../../components/Layout/index";
import { sideBarItems } from "../../utils";
import DriverTable from "../../components/Tables/driver_table";

import TripService from "../../services/TripService";
import _ from "lodash";
import TripTable from "../../components/Tables/trip_table";

function TripList(props) {
  const [trips, setTrips] = useState([]);
  const [isedit, setIsedit] = useState(true);
  const [filteredTrips, setFilteredTrips] = useState([]);

  const [showbidinfo, setshowbidinfo] = useState(false);
  const [bidinfo, setBidinfo] = useState();

  console.log("search: ", filteredTrips)

  const ShowDriveSecthom = (item) => {
    setshowbidinfo(true);
    setBidinfo(item);
  };

  console.log("new_trips", trips);

  useEffect(() => {
    init().catch();
  }, []);

  const init = async () => {
    const res = await TripService.getTrips();
    setTrips((_.isArray(res?.data) && res?.data) || []);
    setFilteredTrips((_.isArray(res?.data) && res?.data) || []);
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredTrips = _.filter(trips, (item) => {
      return (
        item?.fromLocation?.name?.match(new RegExp(searchQuery, "gi")) ||
        item?.toLocation?.name?.match(new RegExp(searchQuery, "gi"))
      );
    });
    setFilteredTrips(filteredTrips);
  };

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.trip.key}
    >
      <TripTable
        trips={filteredTrips || trips}
        isedit={isedit}
        ShowDriveSecthom={ShowDriveSecthom}
      />

      {showbidinfo && (
        <div className={"bg-white w-96 shadow-xl rounded p-5 mt-10 mr-6 ml-5 "}>
          <svg
            onClick={() => setshowbidinfo(!showbidinfo)}
            className="w-6 h-6 cursor-pointer "
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>{" "}
          <p>{JSON.stringify(bidinfo,null,2)}</p>
        </div>
        // <DriverTable />
      )}
    </Layout>
  );
}

export default TripList;
