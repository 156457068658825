import React, { useEffect, useState } from "react";
import { useScroll } from "./utils";
import { Header, Landing, Introduction, AboutPartner, Footer, } from './components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import OwnerList from './screen/OwnerList';
import LandingPage from './screen/LandingPage';
import Admin from './screen/Admin';
import Dashboard from './screen/Dashboard/dashboard';
import Booking from './screen/Booking';
import Layout from "./components/Layout/index"

import Owner from './screen/Owners/index'
import AddLocation from "./components/Location/index";
import BusRoute from "./screen/BusRoute";
import PriceList from "./components/PriceList/index"
import DriverList from "./components/DriverList/index"

import AddPricePage from "./screen/AddPrice/index"
import AuthContext from "./shared/AuthContext";
import useAuthentication from './components/HOC/useAuthentication';
import Passengers from "./components/DriverList/Passengers";
import TripList from "./screen/Trip";
import Transaction from './screen/Transaction';
import Refund from './screen/Refund';
import AdminService from "./services/admin/AdminService";
import Notifications from "./screen/Notifications/Notifications";
import Support from "./screen/Support/index";
import "react-photo-view/dist/react-photo-view.css";
import SupportItem from "./screen/Support/SupportItem";
import { ErrorBoundary } from "react-error-boundary";


function App() {
  const [scrollToContent, contentRef] = useScroll()
  const { user, setUser } = useAuthentication();


  if (user === null) {
    return <Admin setUser={setUser} />
  }

  // useEffect(()=>{
  //   checkSession().catch();
  // },[user]);

  // const checkSession =async ()=>{
  //   const session = await AdminService.isExistSession();
  //   console.log(session);
  // }

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <AuthContext.Provider
        value={{ user, setUser }}>
        <React.Fragment>
          <BrowserRouter>
            <Switch>
              <Route path='/dashboard' component={Dashboard} />
              <Route exact path='/' component={Dashboard} />
              {/*<Route path='/admin' component={Admin} />*/}
              <Route path='/layout' component={Layout} />
              <Route path='/owner' component={Owner} />
              <Route path='/location' component={AddLocation} />
              <Route path='/route' component={BusRoute} />
              <Route path='/price' component={PriceList} />
              <Route path='/booking' component={Booking} />
              <Route path='/driverlist' component={DriverList} />
              <Route path='/passengers' component={Passengers} />
              <Route path='/addprice' component={AddPricePage} />
              <Route path='/Triplist' component={TripList} />
              <Route path='/transaction' component={Transaction} />
              <Route path='/refund' component={Refund} />
              <Route path='/notifications' component={Notifications} />
              <Route path='/support' component={Support} />
              <Route path='/supportitem' component={SupportItem} />



              {/*<Route path='/dashboard' component={DashSidebar} />*/}


            </Switch>
            {/*<div className="box-border">*/}
            {/*  <div id='scroll-container' className="flex flex-col">*/}
            {/*    <OwnerList/>*/}
            {/*    <Header/>*/}
            {/*    <Landing onScroll={scrollToContent}/>*/}
            {/*    <div ref={contentRef}>*/}
            {/*      <Introduction/>*/}
            {/*      <AboutPartner/>*/}
            {/*      <Footer/>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </BrowserRouter>
        </React.Fragment>
      </AuthContext.Provider>
    </ErrorBoundary>
  );
}

export default App;
