import React from "react";
import moment from "moment";

const RefundTable = ({ trips, setVisibilityConformation, visibleConformation }) => {


  const handleRefundClick = (item) => {
    // setSelectedItem(item);
    setVisibilityConformation(!visibleConformation, item);
  };

  const tableHeaders = [
    "Date",
    "From",
    "To",
    "Canceled By",
    "Cancel Type",
    "Trip Status",
    "Action"
  ];

  const renderCanceledBy = (canceledBy) => {
    if (canceledBy?.driver) return `Driver (${canceledBy.driver})`;
    if (canceledBy?.passenger) return `Passenger (${canceledBy.passenger})`;
    return '-';
  };

  const renderTripStatus = (status) => (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full capitalize ${status === 'canceled' ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
      }`}>
      {status}
    </span>
  );

  return (
    <div className="w-full mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {tableHeaders.map((header, index) => (
                <th key={index} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {trips.map((item, index) => (
              <tr key={index} className="hover:bg-gray-50 transition-colors duration-200">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {moment(item.createdAt).format("DD MMM YYYY HH:mm:ss")}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.fromLocation?.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item.toLocation?.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                  {renderCanceledBy(item?.canceledBy)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {item?.canceledBy?.cancelType || '-'}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {renderTripStatus(item?.tripStatus)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  {!item?.canceledBy?.isRefunded && (
                    <button
                      // onClick={() => setVisibilityConformation(!visibleConformation, item)}
                      onClick={() => handleRefundClick(item)}
                      className="
                      bg-gradient-to-r from-red-500 to-red-600
                      hover:from-red-600 hover:to-red-700
                      text-white font-semibold
                      py-2 px-6
                      rounded-full
                      transition duration-300 ease-in-out
                      transform hover:scale-105 hover:shadow-lg
                      focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50
                      shadow-md
                      flex items-center justify-center space-x-2
                    "
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                      </svg>
                      <span>Refund</span>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RefundTable;