import http from "./http";
import { API_BASE_URL } from "../config";

export default class SupportService {
  static getSupportHistory() {
    return http.get(`${API_BASE_URL}/support`).then((res) => res.data);
  }
  static sendSupportChatMessage=(supportId,payload)=> {
    return http.put(
      `${API_BASE_URL}/support/chat/${supportId}`,
      payload
    );
  }

  static getSupportChatMessage(currentTicketId) {
    return http.get(`${API_BASE_URL}/support/chatHistory/${currentTicketId}`);
  }

  static updateTicket(supportId,payload) {
    return http.put(`${API_BASE_URL}/support/update/${supportId}`,payload);
  }
}
