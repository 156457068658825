import React from "react"
import Layout from "../Layout/index"
import PriceTable from "./price_table"
import { Fragment, useState, useEffect } from 'react'
import AdminService from '../../services/admin/AdminService';
import PriceUpdate from "./price_update"
import { Link } from "react-router-dom"
import { sideBarItems } from '../../utils';
import _ from 'lodash'

const PriceList = () => {
  const [owners, setOwners] = useState([]);
  const [locations, setLocations] = useState([]);
  const [fares, setFares] = useState([]);
  const [filteredFares, setFilteredFares] = useState([]);
  const [selectedFareItem, setSelectedFareItem] = useState();
  const [showUpPrice, setShowUpPrice] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);


  const setUpdatePrice = (selectedFareItem) => {
    setVisibleModal(true)
    setSelectedFareItem(selectedFareItem)

  }

  useEffect(() => {
    callApis()
  }, [])

  const callApis = () => {
    AdminService.getFareChart().then(data => {
      setFares(data.data);
      setFilteredFares(data.data);
    })
  }

  const handleChangeSearch = (searchQuery) => {
    let filteredFares = _.filter(fares, item => {
      return item?.from?.match(new RegExp(searchQuery, 'gi'))
        || item?.to?.match(new RegExp(searchQuery, 'gi'))

    });
    setFilteredFares(_.isEmpty(searchQuery) ? fares : filteredFares)
  }

  const handleModal = (isVisible) => {
    setVisibleModal(isVisible)
  }

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      modal={<PriceUpdate
        selectedFareItem={selectedFareItem}
        showUpPrice={showUpPrice}
        refresh={callApis}
      // handleModal={handleModal}
      />}
      visibleModal={visibleModal}
      handleModal={handleModal}
      title={sideBarItems.price.key}>
      <div className="-ml-6 w-full mr-10 mt-10  ">
        <Link
          to="/addprice"
          className=" inline-flex items-center justify-center px-2 py-1.5 text-indigo-600 font-medium border border-indigo-600 rounded-md transition duration-300 ease-in-out bg-indigo-50 hover:bg-indigo-100 hover:text-indigo-700 hover:border-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          // className="inline-flex items-center px-3 py-1.5 border bg-indigo-100 border-indigo-600 text-indigo-600 rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150"
        >
          <svg
            className="w-5 h-5 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          Add Price
        </Link>
        {/* <div>
          {showUpPrice &&
            <PriceUpdate
              selectedFareItem={selectedFareItem}
              showUpPrice={showUpPrice}
              setShowUpPrice={setShowUpPrice}
              refresh={callApis}
            />
          }
        </div> */}
        <PriceTable setUpdatePrice={setUpdatePrice} fares={filteredFares} />
      </div>


    </Layout>

  )
};

export default PriceList;