import React, { useState } from "react";
import _ from 'lodash';

const LocationUpdate = ({ selectedDivision, selectedDistrict, addLocation, setAddPanelStatus, showAddPanel }) => {
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const submitPlace = () => {
    if (!latitude || !longitude) {
      alert("Please add latitude and longitude.");
      return;
    }

    const payload = {
      name: _.startCase(_.toLower(name)),
      name_in_bd: _.startCase(_.toLower(name)),
      type: selectedDistrict?.name ? "subDistrict" : "district",
      district: selectedDistrict?.name ? _.startCase(_.toLower(selectedDistrict.name)) : _.startCase(_.toLower(name)),
      division: _.startCase(selectedDivision?.title),
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      path: selectedDistrict?.name
        ? `${_.toLower(name)}_${selectedDistrict.name}_${_.toLower(selectedDivision?.title)}`
        : `${_.toLower(name)}_${_.toLower(selectedDivision?.title)}`
    };

    addLocation(payload);
    setName('');
    setLatitude('');
    setLongitude('');
  };

  const handleNumericInput = (setter) => (e) => {
    const value = e.target.value;
    if (!isNaN(value)) setter(value);
  };

  return (
    <div className="bg-white w-96 shadow-2xl rounded-lg p-6 mt-10 mr-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-800">Add Location</h2>
        <button
          onClick={() => setAddPanelStatus(!showAddPanel)}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <div className="space-y-4">
        {selectedDivision && (
          <p className="text-sm text-gray-600">Division: <span className="font-medium">{selectedDivision.title}</span></p>
        )}
        {selectedDistrict && (
          <p className="text-sm text-gray-600">District: <span className="font-medium">{selectedDistrict.name}</span></p>
        )}

        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
          placeholder="Location Name"
        />
        <input
          type="text"
          value={latitude}
          onChange={handleNumericInput(setLatitude)}
          className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
          placeholder="Latitude (e.g., 23.811056)"
        />
        <input
          type="text"
          value={longitude}
          onChange={handleNumericInput(setLongitude)}
          className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
          placeholder="Longitude (e.g., 90.407608)"
        />
      </div>

      <button
        onClick={submitPlace}
        className="w-full mt-6 px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-200"
      >
        Add Location
      </button>
    </div>
  );
};

export default LocationUpdate;