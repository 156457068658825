import http from "./http";
import { API_BASE_URL } from "../config";
import _ from "lodash";

export default class FileService {
  static downloadFile = url => {
    const config = {
      params: {
        file: url,
      },
    };
    return http
      .get(`${API_BASE_URL}/downloadable-link`, config)
      .then(response => response.data)
      .catch(err => {
        console.log(err);
      });
  };
  static uploadS3PrivateFile = (payload) => {
    return http
      .post(`${API_BASE_URL}/upload/private/s3`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("upload", err);
      });
  };
  static uploadImage = (payload) => {
    return http
      .post(`${API_BASE_URL}/image`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log("upload", err);
      });
  };

  static getDownloadableLink = async (fileUrl) => {
    if (_.isEmpty(fileUrl)) return null;
    let data = await this.downloadFile(fileUrl);
    // console.log("data:", data)
    // const uri = data?.downloadLink ? data?.downloadLink : null;
    return data?.downloadLink;
  };
}
