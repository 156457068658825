import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import TransTable from "../../components/Tables/transaction_table";
import { sideBarItems } from "../../utils";
import Conformation from "../../components/common/conformation";
import TransationService from "../../services/Transaction";
import _ from "lodash";

const Transaction = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);

  const [transations, setTransations] = useState([]);
  const [filteredTransations, setFilteredTransations] = useState([]);
  const [data,setData]  = useState();

  console.log("setTransations", transations);

  useEffect(() => {
    init().catch();
  }, []);

  const init = async () => {
    const res = await TransationService.getTransaction();
    console.log("res test",res);

    setTransations((_.isArray(res) && res) || []);
    setFilteredTransations((_.isArray(res) && res) || []);
  };

  const ShowJson = (item)=>{
    setVisibilityConformation(!visibleConformation);
    setData(item);

  }

  const handleChangeSearch = (searchQuery) => {
    let filteredTrans = _.filter(transations  , item => {
      return item?.transactionFor?.passenger?.match(new RegExp(searchQuery, 'gi'))
        || item?.transactionFor?.owner?.match(new RegExp(searchQuery, 'gi'))
        || item?._id?.match(new RegExp(searchQuery, 'gi'))
        || item?.transactionFor?.trip?._id?.match(new RegExp(searchQuery, 'gi'))
    });
    setFilteredTransations(filteredTrans)
  }
  

  return (
    <Layout handleChangeSearch={handleChangeSearch} title={sideBarItems.transaction.key} searchTitle={'Search by TRIP ID / TRANSACTIONID'}>
      {/* {visibleConformation && (
        <Conformation
          title={"Verify Driver"}
          msg={"Are you sure to verify"}
          data={data}
          confirm={() => {}}
        />
      )} */}


      <TransTable
         transations={filteredTransations}
        // setVisibilityConformation={setVisibilityConformation}
        // visibleConformation={visibleConformation}
        ShowJson={ShowJson}
      />

      {
        visibleConformation &&
        <div className="mt-12 bg-white  ml-2 mr-4 ">
            <svg
          onClick={() => setVisibilityConformation(!visibleConformation)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>

          {
            JSON.stringify(data)
          }


        </div>
      }
    </Layout>
  );
};

export default Transaction;
