import {useRef} from "react";

export const  useScroll = () => {
  const elRef = useRef(null);
  const executeScroll = () => elRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});

  return [executeScroll, elRef];
};

export const sideBarItems = {
  dashboard: {
    key:"dashboard",
    value:"Dashboard"
  },
  price: {
    key:"price",
    value:"Price list of trip"
  }, 
  booking: {
    key:"booking",
    value:"Booking"
  },
  location: {
    key:"location",
    value:"Locations"
  },
  route: {
    key:"route",
    value:"Bus Route"
  },
  driver: {
    key:"driver",
    value:"Drivers"
  },
  owner: {
    key:"owner",
    value:"Owners"
  },
  passenger: {
    key:"passenger",
    value:"Passengers"
  },
 trip: {
    key:"trip",
    value:"trips"
  },
  transaction:{
    key:"transaction",
    value: 'transaction'
  },
  refund:{
    key:'refund',
    value:'refund'
  },
  notifications:{
    key:'notifications',
    value:'notifications'
  },
  support:{
    key:'support',
    value:'support'
  }

}