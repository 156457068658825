import React, { useState } from "react";
import DashSidebar from "../dashboard_sidebar";
import TWModal from '../common/modal';
import "./index.css";

const Layout = ({ 
  children, 
  title, 
  handleChangeSearch, 
  searchTitle, 
  modal, 
  visibleModal, 
  handleModal, 
  noMargin 
}) => {
  const [search, setSearch] = useState('');

  const handleChangeSearchLocal = (e) => {
    const value = e.target.value.replace(/\+?88|\+/g, ""); 
    setSearch(value);
    if (handleChangeSearch) {
      handleChangeSearch(value);
    }
  };

  return (
    <div className="flex flex-row min-h-screen bg-gray-100">
      <DashSidebar title={title} />
      
      <div className="flex flex-col flex-grow w-full ml-3" style={{ maxWidth: '82%' }}>
        {handleChangeSearch && (
          <div className="bg-indigo-100 p-4 shadow-md">
            <div className="max-w-xl mx-auto">
              <div className="relative">
                <input
                  className="w-full rounded-full py-2 px-4 pr-10 focus:outline-none focus:ring-2 focus:ring-indigo-400 border border-gray-300"
                  type="text"
                  pattern="[a-zA-Z0-9]+"
                  required
                  value={search}
                  onChange={handleChangeSearchLocal}
                  placeholder={searchTitle || "Search by Name / Phone number..."}
                />
                <button
                  onClick={() => handleChangeSearch(search)}
                  className="absolute right-0 top-0 mt-2 mr-3 text-indigo-500 hover:text-indigo-600 focus:outline-none"
                >
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
        
        <main className={`flex-grow p-6 ${noMargin ? '' : 'ml-4'}`}>
          {children}
        </main>
      </div>

      {visibleModal && (
        <TWModal handleModal={handleModal}>
          {modal}
        </TWModal>
      )}
    </div>
  );
};

export default Layout;