import React from "react";
import Layout from "../Layout/index";
import { useState, useEffect } from "react";
import PassengerTable from "../Tables/passenger_table";

import DriverInfomation from "./driverInfo";
import { sideBarItems } from "../../utils";
import _ from "lodash";
import PassengerService from "../../services/PassengerService";
import PassengerModel from "../../components/common/passengers_bid_model";

const PassengersList = () => {
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [passengerInfo, setPassengerInfo] = useState();
  const [isedit, setIsedit] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);


  useEffect(() => {
    initApi();
  }, []);

  const initApi = () => {
    PassengerService.getPassenger().then((data) => {
      setOwners(data);
      setFilteredOwners(data);
    });
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredOwners = _.filter(owners, (item) => {
      return (
        item.mobile.match(new RegExp(searchQuery, "gi")) ||
        item?.name?.match(new RegExp(searchQuery, "gi")) ||
        item._id.match(new RegExp(searchQuery, "gi"))
      );
    });
    setFilteredOwners(filteredOwners);
  };

  const handleModal = (isVisible) => {
    setVisibleModal(isVisible);
  };

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.passenger.key}
      modal={
        <PassengerModel
          handleModal={handleModal}
          passengerInfo={passengerInfo}

        // carInfo={carInfo}
        />
      }
      visibleModal={visibleModal}
      handleModal={handleModal}
    >
      {/*<Conformation/>*/}
      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <PassengerTable
            tableFor="passenger"
            owners={filteredOwners}
            isedit={isedit}
            handleModal={(isVisible, item) => {
              handleModal(isVisible);
              setPassengerInfo(item);
            }}
          />
        </div>
      </div>
    </Layout>
  );
};
export default PassengersList;
