// import React,{useState,useEffect} from "react"
// import AdminService from '../../services/admin/AdminService';

// const PriceUpdate = (props) => {
//   const [fareForSmall, setFareForSmall] = useState();
//   const [fareForLarge, setFareForLarge] = useState();
//   const [fareForRound, setFareForRound] = useState();

//   useEffect(()=>{
//     setFareForSmall(props.selectedFareItem?.fare_u_turn_trip)
//     setFareForLarge(props.selectedFareItem?.fare_forward_trip)
//     setFareForRound(props.selectedFareItem?.fare_round_trip)
//   },[props.selectedFareItem])

//   const update = ()=>{
//     let paylod = {
//       fare:fareForSmall,
//       fare_u_turn_trip:fareForSmall,
//       fare_forward_trip:fareForLarge,
//       fare_round_trip:fareForRound
//     }
//     AdminService.updateFare(props.selectedFareItem._id,paylod).then(data => {
//       props.setShowUpPrice(!props.showUpPrice);
//       props.refresh();
//     })

//   }

//   return (
//     <div className={"bg-white w-80 shadow-xl rounded p-5 mt-10 mr-6"}>
//       <div
//         className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg"
//       >
//         <p className="font-semibold text-gray-800"></p>
//         <svg
//           onClick={() => props.setShowUpPrice(!props.showUpPrice)}
//           className="w-6 h-6 cursor-pointer "
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             stroke-linecap="round"
//             stroke-linejoin="round"
//             stroke-width="2"
//             d="M6 18L18 6M6 6l12 12"
//           ></path>
//         </svg>
//       </div>

//       <p className=" text-gray-500 font-medium">Place 1 : {props?.selectedFareItem?.from} </p>
//       <p className=" mt-2 text-gray-500 font-medium">Place 2 : {props?.selectedFareItem?.to}</p>

//       <form className="space-y-5 mt-5">
//         <input
//           type="text"
//           value={fareForSmall}
//           onChange={(e)=>{
//             setFareForSmall(e.target.value)
//           }}
//           className="  w-64 h-12 border border-gray-800 rounded px-3"
//           placeholder="Fare for u-turn trip"
//         />
//         <input
//           type="text"
//           value={fareForLarge}
//           onChange={(e)=>{
//             setFareForLarge(e.target.value)
//           }}
//           className="  w-64 h-12 border border-gray-800 rounded px-3"
//           placeholder="Fare for forward trip"
//         />
//           <input
//           type="text"
//           value={fareForRound}
//           onChange={(e)=>{
//             setFareForRound(e.target.value)
//           }}
//           className="  w-64 h-12 border border-gray-800 rounded px-3"
//           placeholder="Fare for round trip"
//         />
//         {(fareForSmall && fareForLarge && fareForRound) ?
//         <button
//           onClick={update}
//           className=" w-64  mt-10 bg-indigo-800 px-5 py-3 text-sm shadow-sm font-medium tracking-wider border text-indigo-100 rounded-full hover:shadow-lg hover:bg-indigo-900">Update
//         </button>
//         :null}
//       </form>
//     </div>

//   )

// };
// export default PriceUpdate;




import React, { useState, useEffect } from "react";
import AdminService from '../../services/admin/AdminService';

const PriceUpdate = (props) => {
  const [fareForSmall, setFareForSmall] = useState('');
  const [fareForLarge, setFareForLarge] = useState('');
  const [fareForRound, setFareForRound] = useState('');

  useEffect(() => {
    setFareForSmall(props.selectedFareItem?.fare_u_turn_trip || '');
    setFareForLarge(props.selectedFareItem?.fare_forward_trip || '');
    setFareForRound(props.selectedFareItem?.fare_round_trip || '');
  }, [props.selectedFareItem]);

  const update = () => {
    let payload = {
      fare: fareForSmall,
      fare_u_turn_trip: fareForSmall,
      fare_forward_trip: fareForLarge,
      fare_round_trip: fareForRound
    };

    AdminService.updateFare(props.selectedFareItem._id, payload).then(data => {
      props.setShowUpPrice(!props.showUpPrice);
      props.refresh();
    });
  };

  const handleInputChange = (value, setterFunction) => {
    // If the input is empty, update the state to an empty string
    if (value === '') {
      setterFunction('');
      return;
    }

    // Check if the input is a valid number greater than or equal to 0
    if (!isNaN(value) && parseFloat(value) >= 0) {
      setterFunction(value);
    }
  };

  return (
    <div className={"bg-white w-80 shadow-xl rounded p-5 mt-10 mr-6"}>
      {/* <div className="flex flex-row justify-between rounded-tl-lg rounded-tr-lg">
        <p className="font-semibold text-gray-800"></p>
        <svg
          onClick={() => props.setShowUpPrice(!props.showUpPrice)}
          className="w-6 h-6 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div> */}

      <p className="text-gray-500 font-medium">Place 1 : {props?.selectedFareItem?.from}</p>
      <p className="mt-2 text-gray-500 font-medium">Place 2 : {props?.selectedFareItem?.to}</p>

      <form className="space-y-5 mt-5">
        <input
          type="text"
          value={fareForSmall}
          onChange={(e) => handleInputChange(e.target.value, setFareForSmall)}
          className="w-64 h-12 border border-gray-800 rounded px-3"
          placeholder="Fare for u-turn trip"
        />
        <input
          type="text"
          value={fareForLarge}
          onChange={(e) => handleInputChange(e.target.value, setFareForLarge)}
          className="w-64 h-12 border border-gray-800 rounded px-3"
          placeholder="Fare for forward trip"
        />
        <input
          type="text"
          value={fareForRound}
          onChange={(e) => handleInputChange(e.target.value, setFareForRound)}
          className="w-64 h-12 border border-gray-800 rounded px-3"
          placeholder="Fare for round trip"
        />
        {(fareForSmall && fareForLarge && fareForRound) &&
          <button
            onClick={update}
            className="w-64 mt-10 bg-indigo-800 px-5 py-3 text-sm shadow-sm font-medium tracking-wider border text-indigo-100 rounded-full hover:shadow-lg hover:bg-indigo-900">Update
          </button>
        }
      </form>
    </div>
  );
};

export default PriceUpdate;


