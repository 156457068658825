import React, {useState, useEffect} from "react"
import _ from 'lodash'
const RouteAdd = (props) => {
  const [name, setName] = useState('');

  return (
    <div className={"bg-white w-80 shadow-xl rounded p-5 mt-10 mr-6"}>
      <div
        className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800"></p>
        <svg
          onClick={() => props.setAddPanelStatus(false)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>

      <div className="flex-1 flex items-center justify-center px-2 py-2 lg:justify-end">
        <div className="max-w-lg w-full lg:max-w-xs">
          <label htmlFor="search" className="sr-only">Search</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <input
              onChange={(e)=>{
                setName(e.target.value)
              }}
              id="search"
                   name="Route name"
                   className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   placeholder="Search" type="search"/>
          </div>
        </div>
      </div>
    {/*  addRoute*/}
      <button
        onClick={()=> {
          const name2 = name;
          props.addRoute(name2)
           setName('')
        }}
        type="button"
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
        Add new route
      </button>
    </div>
  )
};
export default RouteAdd;
