import React, {useState, useEffect} from "react"
import _ from 'lodash'
const LocationAdd = (props) => {
  const [name, setName] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [locations, setLocations] = useState(props.locations);
  const [filteredLocation, setFilteredLocation] = useState(props.locations);

  const handleChangeSearch = (searchQuery) => {
    let filteredLocation = _.filter(locations, item => {
      return item?.name?.match(new RegExp(searchQuery, 'gi'))
    });
    setFilteredLocation(filteredLocation)
  }

  return (
    <div className={"bg-white w-80 shadow-xl rounded p-5 mt-10 mr-6"}>
      <div
        className="  flex flex-row justify-between  rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800"></p>
        <svg
          onClick={() => props.setAddPanelStatus(null)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>

      <div className="flex-1 flex items-center justify-center px-2 py-2 lg:justify-end">
        <div className="max-w-lg w-full lg:max-w-xs">
          <label htmlFor="search" className="sr-only">Search</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <input
              onChange={(e)=>{
                handleChangeSearch(e.target.value)
              }}
              id="search"
                   name="search"
                   className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   placeholder="Search" type="search"/>
          </div>
        </div>
      </div>

      {filteredLocation.map(item => {
        return (
          <a
            onClick={()=>props.addLocationToRoute(item)}
             className="bg-gray-100 text-gray-900 group flex items-center px-3 py-2 my-2 text-sm font-medium rounded-md"
             aria-current="page">
            <span className="truncate">
              {item.name}
    </span>
          </a>
        )
      })}

    </div>
  )
};
export default LocationAdd;
