import http from "./http";
import { API_BASE_URL } from "../config";

export default class PassengerService {
  static getPassenger() {
    return http
      .get(`${API_BASE_URL}/passengers`)
      .then((response) => response.data);
  }
  static getPassengerPg(page,limit,searchQuery={}) {
    const config = {
      params:{
        page,
        limit,
        ...searchQuery,
      }
    }
    
    return http
      .get(`${API_BASE_URL}/v2/passengers`,config)
      .then((response) => response.data);
  }
  

}
