const OwnerSearch = ({ searchQuery, handleSearchQuery, clearSearch, searchOwner }) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Name</label>
          <input
            type="text"
            placeholder="Search by name"
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => handleSearchQuery('name', e.target.value)}
            value={searchQuery.name || ''}
          />
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Mobile</label>
          <input
            type="number"
            placeholder="Search by mobile"
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => {
              let value = e.target.value;

              // Remove leading 88 if present
              if (value.startsWith('88')) {
                value = value.slice(2);
              }

              // Create/get error message div
              const errorDiv = e.target.parentElement.querySelector('.error-message') || document.createElement('div');
              errorDiv.className = 'error-message text-red-500 text-sm mt-1';

              // Validate length
              if (value.length < 11 && value.length > 0) {
                errorDiv.textContent = 'Phone number must be 11 digits';
              } else {
                errorDiv.textContent = '';
              }

              // Add error div if not present
              if (!e.target.parentElement.querySelector('.error-message')) {
                e.target.parentElement.appendChild(errorDiv);
              }

              // Only allow 11 or fewer digits
              let regex = /^[0-9]{0,11}$/;
              if (regex.test(value)) {
                handleSearchQuery('mobile', value)
              }
            }}
            value={searchQuery.mobile || ''}
          />
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Vehicle Status</label>
          <select
            name="hasVehicle"
            id="hasVehicle"
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => handleSearchQuery('hasVehicle', e.target.value)}
            value={searchQuery.hasVehicle || 'all'}
          >
            <option value="all">All Vehicles</option>
            <option value="yes">Has Vehicle</option>
            <option value="no">No Vehicle</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">Driver Status</label>
          <select
            name="hasDriver"
            id="hasDriver"
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => handleSearchQuery('hasDriver', e.target.value)}
            value={searchQuery.hasDriver || 'all'}
          >
            <option value="all">All Drivers</option>
            <option value="yes">Has Driver</option>
            <option value="no">No Driver</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end mt-4 space-x-3">
        <button
          className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
          onClick={clearSearch}
        >
          Clear ➡
        </button>
        <button
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={() => searchOwner()}
          onKeyDown={(e) => e.key === 'return' && searchOwner()}
        >
          Search
        </button>
      </div>
    </div>
  )
}

export default OwnerSearch;