import React, { useState, useEffect } from "react";
import FileService from "../../services/FileService";
import { PhotoProvider, PhotoView } from "react-photo-view";

const CACHE_DURATION = 3600000; // 1 hour in milliseconds

const ImageS3 = ({ uri, style }) => {
  const [s3PublicUri, setS3PublicUri] = useState(null);

  useEffect(() => {
    if (uri) {
      getShowImage().catch(console.error);
    }
  }, [uri]);

  const getShowImage = async () => {
    const cachedImage = getCachedImage(uri);
    if (cachedImage) {
      setS3PublicUri(cachedImage);
    } else {
      const res = await FileService.getDownloadableLink(uri);
      if (res) {
        setS3PublicUri(res);
        cacheImage(uri, res);
      }
    }
  };

  const getCachedImage = (key) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { url, expiry } = JSON.parse(cachedData);
      if (Date.now() < expiry) {
        return url;
      } else {
        localStorage.removeItem(key);
      }
    }
    return null;
  };

  const cacheImage = (key, url) => {
    const cacheData = {
      url,
      expiry: Date.now() + CACHE_DURATION,
    };
    localStorage.setItem(key, JSON.stringify(cacheData));
  };

  return (
    <div>
      {s3PublicUri && (
        <PhotoProvider>
          <PhotoView src={s3PublicUri}>
            <img className={style} src={s3PublicUri} alt="" />
          </PhotoView>
        </PhotoProvider>
      )}
    </div>
  );
};

export default ImageS3;