import React from "react";
import Layout from "../Layout/index";
import { useState, useEffect } from "react";
import PassengerTable from "../Tables/passenger_table";

import DriverInfomation from "./driverInfo";
import { sideBarItems } from "../../utils";
import _ from "lodash";
import PassengerService from "../../services/PassengerService";
import PassengerModel from "../../components/common/passengers_bid_model";
import DriverSearch from "../../components/DriverSearch"
import {Pagination} from "../../components/common/pagination"
const PassengersList = () => {
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [passengerInfo, setPassengerInfo] = useState();
  const [isedit, setIsedit] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);

  // pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalPages, setTotalPages] = useState(1);
  const [name,setName]=useState();
  const [mobile,setMobile]=useState('');
  const [searchQuery,setSearchQuery]=useState({});

  useEffect(() => {
    initApi();
  }, [page,limit]);
  const handleSearchQuery=(type,value)=>{
    switch(type){
      case 'name':
      case 'mobile':
        if(value){
          setSearchQuery((prev)=>({ ...prev ,[type]:value}))
        }else{
          setSearchQuery(prev=>{
            const newQuery={...prev};
               delete newQuery[type];
               return newQuery;
          })
        }
          break;
        default:
          break;
    }
  }
  
  const initApi = () => {
    PassengerService.getPassengerPg(page,limit,searchQuery).then((data) => {
      setOwners(data.users);
      setFilteredOwners(data.users);
      setTotalPages(data.totalPages);
    });
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredOwners = _.filter(owners, (item) => {
      return (
        String(item.mobile).match(new RegExp(searchQuery, "gi")) ||
        item?.name?.match(new RegExp(searchQuery, "gi")) ||
        item._id.match(new RegExp(searchQuery, "gi"))
      );
    });
    setFilteredOwners(filteredOwners);
  };
  const searchPassenger = () => {
    console.log("searchQuery", searchQuery)
    setPage(1);
    initApi();
  }
  const handleModal = (isVisible) => {
    setVisibleModal(isVisible);
  };
  const nextPage=()=>{
    if(page<totalPages){
      setPage((pre)=>pre+1)
    }
  }
  const clearSearch = () => {
    setSearchQuery({});
    setPage(1);
  }
  const prevPage=()=>{
    if(page>1){
      setPage(page-1);
    }
  }

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.passenger.key}
      modal={
        <PassengerModel
          handleModal={handleModal}
          passengerInfo={passengerInfo}

        // carInfo={carInfo}
        />
      }
      visibleModal={visibleModal}
      handleModal={handleModal}
    >
      {/*<Conformation/>*/}
      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <DriverSearch
          searchQuery={searchQuery}
          handleSearchQuery={handleSearchQuery}
          searchOwner={searchPassenger}
          clearSearch={clearSearch}
          />
          <PassengerTable
            tableFor="passenger"
            owners={filteredOwners}
            isedit={isedit}
            handleModal={(isVisible, item) => {
              handleModal(isVisible);
              setPassengerInfo(item);
            }}
          />
          <div className="flex justify-center items-center mt-6 space-x-4">
            <Pagination
              page={page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default PassengersList;
