import { useState } from 'react';
import AdminService from '../../services/admin/AdminService';

export default function useAuthentication() {
  const getUser = () => {
    // AdminService.isExistSession();
    const userString = localStorage.getItem('admin');
    const user = JSON.parse(userString);
    return user;
  };

  const [user, setUser] = useState(getUser());

  const saveUser = user => {
    localStorage.setItem('admin', JSON.stringify(user));
    setUser(user);
  };

  return {
    setUser: saveUser,
    user
  }
}
