import http from "./http";
import { API_BASE_URL } from "../config";

export default class NotificationService {
  static postNotification = (payload) => {
    http
      .post(`${API_BASE_URL}/pushnotification`, payload)
      .then((res) => res.data);
  };
}
