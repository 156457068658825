import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout/index";
import { sideBarItems } from "../../utils";
import BookingService from "../../services/BookingService";

const Booking = () => {
  const [driverBooking, setDriverBooking] = useState({ amount: "", type: "fixed" });
  const [passengerBooking, setPassengerBooking] = useState({ amount: "", type: "fixed" });
  const [editMode, setEditMode] = useState({ driver: false, passenger: false });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const data = await BookingService.getBookingData();
      setDriverBooking({
        amount: data?.driver?.bookingValue || "",
        type: data?.driver?.bookingType || "fixed"
      });
      setPassengerBooking({
        amount: data?.passenger?.bookingValue || "",
        type: data?.passenger?.bookingType || "fixed"
      });
      setEditMode({ driver: false, passenger: false });
    } catch (error) {
      console.error("Failed to fetch booking data:", error);
    }
  };

  const handleAmountChange = (e, userType) => {
    const amount = Math.max(0, Number(e.target.value));
    userType === "driver"
      ? setDriverBooking({ ...driverBooking, amount })
      : setPassengerBooking({ ...passengerBooking, amount });
  };

  const handleTypeChange = (e, userType) => {
    userType === "driver"
      ? setDriverBooking({ ...driverBooking, type: e.target.value })
      : setPassengerBooking({ ...passengerBooking, type: e.target.value });
  };

  const handleUpdate = async (userType) => {
    const isDriver = userType === "driver";
    const currentEditMode = editMode[userType];
    const bookingData = isDriver ? driverBooking : passengerBooking;

    if (currentEditMode) {
      try {
        await BookingService.updateBookingData({
          bookingFor: userType,
          bookingType: bookingData.type,
          bookingValue: bookingData.amount,
        });
        await init();
      } catch (error) {
        console.error(`Failed to update ${userType} booking:`, error);
      }
    }

    setEditMode({ ...editMode, [userType]: !currentEditMode });
  };
  const BookingForm = ({ userType, booking, isEditing }) => (
    <div className="bg-white shadow-md rounded-lg p-6 mb-8">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Booking Setting For {userType}</h2>
      <div className="flex flex-col md:flex-row md:items-end space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex-1">
          <label htmlFor={`${userType}-amount`} className="block text-sm font-medium text-gray-700 mb-1">
            Booking Amount
          </label>
          <input
            id={`${userType}-amount`}
            type="number"
            value={booking.amount}
            onChange={(e) => handleAmountChange(e, userType)}
            disabled={!isEditing}
            className={`w-full px-3 py-2 border rounded-md ${isEditing ? 'border-indigo-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 h-10`}
            placeholder="Enter amount"
          />
        </div>
        <div className="flex-1">
          <label htmlFor={`${userType}-type`} className="block text-sm font-medium text-gray-700 mb-1">
            Booking Type
          </label>
          <select
            id={`${userType}-type`}
            value={booking.type}
            onChange={(e) => handleTypeChange(e, userType)}
            disabled={!isEditing}
            className={`w-full px-3 py-2 border rounded-md ${isEditing ? 'border-indigo-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-indigo-500 h-10`}
          >
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>
        </div>
        <div className="flex-none">
          <button
            onClick={() => handleUpdate(userType)}
            className={`
              inline-flex items-center w-24 justify-center
              border border-transparent text-sm font-medium rounded-md 
              ${isEditing
                ? 'text-green-700 bg-green-100 hover:bg-green-200 focus:ring-green-500'
                : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:ring-indigo-500'
              }
              focus:outline-none focus:ring-2 focus:ring-offset-2
              transition-colors duration-150
              h-10
            `}
          >
            <svg
              className="h-4 w-4 mr-1.5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isEditing ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              )}
            </svg>
            {isEditing ? 'Update' : 'Edit'}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <Layout title={sideBarItems.booking.key}>
      <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Booking Settings</h1>
        <BookingForm
          userType="driver"
          booking={driverBooking}
          isEditing={editMode.driver}
        />
        <BookingForm
          userType="passenger"
          booking={passengerBooking}
          isEditing={editMode.passenger}
        />
      </div>
    </Layout>
  );
};

export default Booking;