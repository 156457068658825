import React, { useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Layout from "../../components/Layout";
import { sideBarItems } from "../../utils";
import SupportService from "../../services/SupportService";

function Support() {
  const [supportHistoryData, setSupportHistoryData] = useState([]);
  const [ticket, setTicket] = useState();
  const [redirectToSupport, setRedirectToSupport] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await SupportService.getSupportHistory();
      setSupportHistoryData(data);
    };
    fetchData();
  }, []);

  const handleClick = (item) => {
    setTicket(item);
    setRedirectToSupport(true);
  };

  if (redirectToSupport) {
    return <Redirect to={{ pathname: "/supportitem", state: { data: ticket } }} />;
  }

  const SupportColumn = ({ title, status }) => (
    <div className="w-1/3 px-4">
      <h2 className="text-xl font-semibold mb-4 text-center bg-gray-100 py-2 rounded-t-lg">{title}</h2>
      <div className="bg-white rounded-b-lg shadow-md overflow-hidden">
        {supportHistoryData
          .filter((item) => item.status === status)
          .map((item, index) => (
            <div key={index} className="border-b last:border-b-0 p-4 hover:bg-gray-50 transition-colors duration-150">
              <h3 className="font-medium text-gray-800 mb-2">{item.title}</h3>
              <button
                onClick={() => handleClick(item)}
                className="text-sm bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded transition-colors duration-150"
              >
                View
              </button>
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <Layout title={sideBarItems.support.key} noMargin>
      <div className="p-6 bg-gray-100 min-h-screen">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Support Tickets</h1>
        <div className="flex space-x-6">
          <SupportColumn title="Initiated" status="Initiated" />
          <SupportColumn title="In Review" status="InReview" />
          <SupportColumn title="Resolved" status="Resolved" />
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Support);