import React, { useEffect, useState } from "react";
import LocationService from '../../services/owner/LocationService';
import _ from 'lodash';

const UpdateSubDistrict = ({ selectedLocation, updateLocation, setUpdatePanelStatus, showUpdatePanel, fetchLocation }) => {
  const [name, setName] = useState(selectedLocation.name || '');
  const [latitude, setLatitude] = useState(selectedLocation.latitude || '');
  const [longitude, setLongitude] = useState(selectedLocation.longitude || '');

  const [subDistrictName, setSubDistrictName] = useState('');
  const [subDistrictLatitude, setSubDistrictLatitude] = useState('');
  const [subDistrictLongitude, setSubDistrictLongitude] = useState('');

  useEffect(() => {
    setName(selectedLocation.name || '');
    setLatitude(selectedLocation.latitude || '');
    setLongitude(selectedLocation.longitude || '');
  }, [selectedLocation]);

  const handleUpdateLocation = () => {
    const payload = {
      name: _.startCase(_.toLower(name)),
      name_in_bd: _.startCase(_.toLower(name)),
      district: _.startCase(_.toLower(name)),
      division: _.startCase(selectedLocation?.division),
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      path: `${_.toLower(name)}_${_.toLower(selectedLocation?.division)}`
    };

    if (selectedLocation?.type === 'subDistrict') {
      payload.district = _.startCase(_.toLower(selectedLocation?.district));
      payload.path = `${_.toLower(name)}_${selectedLocation?.district}_${_.toLower(selectedLocation?.division)}`;
    }

    updateLocation(selectedLocation._id, payload);
    setUpdatePanelStatus(!showUpdatePanel);
  };

  const handleAddSubLocation = () => {
    const payload = {
      name: subDistrictName,
      type: "subDistrict",
      latitude: parseFloat(subDistrictLatitude),
      longitude: parseFloat(subDistrictLongitude),
    };

    LocationService.addSubLocation(selectedLocation._id, payload)
      .then(() => fetchLocation());
  };

  return (
    <div className="bg-white w-96 shadow-2xl rounded-lg p-6 mt-10 mr-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-gray-800">Update Location</h2>
        <button
          onClick={() => setUpdatePanelStatus(!showUpdatePanel)}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>

      <div className="space-y-4 mb-6">
        {selectedLocation?.division && (
          <p className="text-sm text-gray-600">Division: <span className="font-medium">{selectedLocation.division}</span></p>
        )}
        {selectedLocation?.district && (
          <p className="text-sm text-gray-600">District: <span className="font-medium">{selectedLocation.district}</span></p>
        )}
      </div>

      <form className="space-y-4">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
          placeholder="Location Name"
        />
        <input
          type="text"
          value={latitude}
          onChange={(e) => setLatitude(e.target.value)}
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
          placeholder="Latitude (e.g., 23.811056)"
        />
        <input
          type="text"
          value={longitude}
          onChange={(e) => setLongitude(e.target.value)}
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
          placeholder="Longitude (e.g., 90.407608)"
        />

        <button
          type="button"
          onClick={handleUpdateLocation}
          className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-200"
        >
          Update Location
        </button>
      </form>

      {selectedLocation?.type !== 'subDistrict' && (
        <div className="mt-8">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Add Sub-District</h3>
          <form className="space-y-4">
            <input
              type="text"
              value={subDistrictName}
              onChange={(e) => setSubDistrictName(e.target.value)}
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Sub-District Name"
            />
            <input
              type="text"
              value={subDistrictLatitude}
              onChange={(e) => setSubDistrictLatitude(e.target.value)}
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Sub-District Latitude"
            />
            <input
              type="text"
              value={subDistrictLongitude}
              onChange={(e) => setSubDistrictLongitude(e.target.value)}
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Sub-District Longitude"
            />
            <button
              type="button"
              onClick={handleAddSubLocation}
              className="w-full px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-colors duration-200"
            >
              Add Sub-District
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateSubDistrict;