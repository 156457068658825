import React from "react";
import Layout from "../Layout/index";
import { useState, useEffect } from "react";
import AdminService from "../../services/admin/AdminService";
import DriverTable from "../Tables/driver_table";
import DriverInfo from "../Tables/driver_manage_info";
import Conformation from "../common/conformation";
import DriverSearch from "../../components/DriverSearch"
import {Pagination} from "../../components/common/pagination"
import DriverInfomation from "./driverInfo";
import CarInfoModel from "../common/car_info_model";
import { sideBarItems } from "../../utils";
import _ from "lodash";
import TripService from "../../services/TripService";
import DriverBidInfo from '../../components/common/driver_bid_model'

const DriverList = () => {
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [driveinfo, setDriveinfo] = useState();
  const [isedit, setIsedit] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);


  const [selectedTrip, setSelectedTrip] = useState();
  const [individualBId, setIndividualBId] = useState([]);

  // for pagination state
  const [page,setPage]=useState(1);
  const [limit,setLimit]=useState(15);
  const [totalPages,setTotalPages]=useState(1);
  const [searchQuery,setSearchQuery]=useState({});


  useEffect(() => {
    initApi();
  }, [page,limit]);

  const individualBIdApi = async () => {
    // your need to pass driver id  then call
    const res = await TripService.getTripByDriver();
    setIndividualBId((_.isArray(res?.data) && res?.data) || []);
  };

  const initApi = () => {
    AdminService.getDriversPg(page,limit,searchQuery).then((data) => {
      setOwners(data.data.users);
      setFilteredOwners(data.data.users);
      setTotalPages(data.data.totalPages)
    });
  };
  console.log("Response>>>>>>",owners)

  const handleChangeSearch = (searchQuery) => {
    let filteredOwners = _.filter(owners, (item) => {
      return (
        item?.mobile?.match(new RegExp(searchQuery, "gi")) ||
        item?._id?.match(new RegExp(searchQuery, "gi")) ||
        // item?.firstName?.match(new RegExp(searchQuery, "gi")) ||
        item?.myOwners[0]?.name?.match(new RegExp(searchQuery, "gi")) ||
        // item?.lastName?.match(new RegExp(searchQuery, "gi"))
        item?.name?.match(new RegExp(searchQuery, "gi"))

      );
    });
    setFilteredOwners(filteredOwners);
  };
  const handleSearchQuery=(type,value)=>{
    switch(type){
      case 'name':
      case 'mobile':
        if(value){
          setSearchQuery((prev)=>({ ...prev ,[type]:value}))
        }else{
          setSearchQuery(prev=>{
            const newQuery={...prev};
               delete newQuery[type];
               return newQuery;
          })
        }
          break;
        default:
          break;
    }
  }
  const searchOwner = () => {
    console.log("searchQuery", searchQuery)
    setPage(1);
    initApi();
  }
  const clearSearch = () => {
    setSearchQuery({});
    setPage(1);
  }
  const handleModal = (isVisible) => {
    setVisibleModal(isVisible)
  }
  const nextPage=()=>{
    if(page){
      setPage((pre)=>pre+1)
    }
  }
  const prevPage=()=>{
    if(page>1){
      setPage(page-1)
    }
  }

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.driver.key}
      modal={
        <DriverBidInfo
          handleModal={handleModal}
          driveinfo={driveinfo}

        // carInfo={carInfo}
        />
      }

      visibleModal={visibleModal}
      handleModal={handleModal}
    >
      {/*<Conformation/>*/}
      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <DriverSearch 
          searchQuery={searchQuery}
          handleSearchQuery={handleSearchQuery}
          searchOwner={searchOwner}
          clearSearch={clearSearch}

          />
          <DriverTable
            tableFor='driver'
            owners={filteredOwners}
            isedit={isedit}
            refresh={initApi}
            handleModal={(isVisible, item) => {
              setDriveinfo(item);
              handleModal(isVisible)
            }}
          />
          <div className="flex justify-center items-center mt-6 space-x-4">
            <Pagination
              page={page}
              totalPages={totalPages}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default DriverList;
