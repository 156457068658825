import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Layout from "../../components/Layout/index";
import CityList from "./CityList";
import AdminService from '../../services/admin/AdminService';
import LocationService from '../../services/owner/LocationService';

const AddPricePage = ({ history }) => {
  const [locations, setLocations] = useState([]);
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [fromCity, setFromCity] = useState(null);
  const [toCity, setToCity] = useState(null);
  const [fareForSmall, setFareForSmall] = useState('');
  const [fareForLarge, setFareForLarge] = useState('');
  const [fareForRoundTrip, setFareForRoundTrip] = useState('');

  useEffect(() => {
    LocationService.getLocations({}).then(data => {
      setLocations(data);
      setFilteredLocation(data);
    });
  }, []);

  const handleChangeSearch = (searchQuery) => {
    const filteredLocation = _.filter(locations, item => 
      item?.name?.match(new RegExp(searchQuery, 'gi'))
    );
    setFilteredLocation(filteredLocation);
  };

  const saveFare = () => {
    const path1 = fromCity.path.split('_').slice(1).join('_');
    const path2 = toCity.path.split('_').slice(1).join('_');
    const payload = {
      fare: fareForSmall,
      fare_u_turn_trip: fareForSmall,
      fare_forward_trip: fareForLarge,
      fare_round_trip: fareForRoundTrip,
      from: fromCity.path,
      fromId: fromCity._id,
      to: toCity.path,
      toId: toCity._id,
      path: (path1 + '-' + path2).toLowerCase()
    };

    AdminService.addFare(payload).then(() => {
      history.push('/price');
    });
  };

  const renderCityCard = (city, label, onRemove) => (
    city && (
      <div className="mt-4 bg-blue-100 border-blue-300 p-4 rounded-lg border-2 flex justify-between items-center hover:bg-blue-200 transition-colors duration-300">
        <div className="flex items-center overflow-hidden">
          <p className="text-gray-900 font-semibold mr-2 whitespace-nowrap">{label}:</p>
          <p className="text-black font-semibold truncate">{city.path}</p>
        </div>
        <button onClick={() => onRemove(null)} className="text-gray-600 hover:text-gray-800 ml-2 flex-shrink-0">
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    )
  );

  const renderInput = (value, onChange, placeholder) => (
    <div className="mt-4 relative">
      <input
        type="number"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="w-full p-3 border-2 border-blue-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
        placeholder={placeholder}
      />
    </div>
  );

  return (
    <Layout handleChangeSearch={handleChangeSearch}>
      <div className="flex flex-col lg:flex-row w-full px-4 lg:px-8 py-6 gap-6 ">
        <div className="w-full lg:w-2/3">
          <CityList
            locations={filteredLocation}
            saveFromCity={setFromCity}
            saveToCity={setToCity}
          />
        </div>

        <div className="w-full lg:w-1/3 space-y-4">
          {renderCityCard(fromCity, "FROM CITY", setFromCity)}
          {renderCityCard(toCity, "TO CITY", setToCity)}

          {(toCity && fromCity) && (
            <div className="space-y-4 bg-white p-4 rounded-lg shadow-md">
              {renderInput(fareForSmall, setFareForSmall, "Fare for u-turn trip")}
              {renderInput(fareForLarge, setFareForLarge, "Fare for forward trip")}
              {renderInput(fareForRoundTrip, setFareForRoundTrip, "Fare for round trip")}

              {(fareForLarge && fareForSmall && fareForRoundTrip) && (
                <button
                  onClick={saveFare}
                  className="w-full mt-4 bg-indigo-600 px-6 py-2 text-white font-medium rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-300"
                >
                  SAVE
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(AddPricePage);