import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import TransTable from "../../components/Tables/transaction_table";
import { sideBarItems } from "../../utils";
import Conformation from "../../components/common/conformation";
import TransationService from "../../services/Transaction";
import _ from "lodash";

const Transaction = (props) => {
  const [visibleConformation, setVisibilityConformation] = useState(false);

  const [transations, setTransations] = useState([]);
  const [filteredTransations, setFilteredTransations] = useState([]);
  const [data,setData]  = useState();
  const [page,setPage]=useState(1);
  const [limit,setLimit]=useState(15);
  const [totalPages,setTotalPages]=useState(1); 

  console.log("setTransations", transations);

  useEffect(() => {
    init().catch();
  }, [page]);

  const init = async () => {
    const data = await TransationService.getTransaction({page,limit});
    console.log("res test",data.data.data);
    const res=data.data.data;

    setTransations((_.isArray(res) && res) || []);
    setFilteredTransations((_.isArray(res) && res) || []);
     setTotalPages(data.data.pagination.totalPages)
  };

  const ShowJson = (item)=>{
    setVisibilityConformation(!visibleConformation);
    setData(item);

  }
  const nextPage=()=>{
    if(page){
      setPage((pre)=>pre+1)
    }
  }
  const prevPage=()=>{
    if(page>1){
      setPage(page-1)
    }
  }

  const handleChangeSearch = (searchQuery) => {
    let filteredTrans = _.filter(transations  , item => {
      return item?.transactionFor?.passenger?.match(new RegExp(searchQuery, 'gi'))
        || item?.transactionFor?.owner?.match(new RegExp(searchQuery, 'gi'))
        || item?._id?.match(new RegExp(searchQuery, 'gi'))
        || item?.transactionFor?.trip?._id?.match(new RegExp(searchQuery, 'gi'))
    });
    setFilteredTransations(filteredTrans)
  }
  

  return (
    <Layout handleChangeSearch={handleChangeSearch} title={sideBarItems.transaction.key} searchTitle={'Search by TRIP ID / TRANSACTIONID'}>
      {/* {visibleConformation && (
        <Conformation
          title={"Verify Driver"}
          msg={"Are you sure to verify"}
          data={data}
          confirm={() => {}}
        />
      )} */}

    
      <TransTable
         transations={filteredTransations}
        // setVisibilityConformation={setVisibilityConformation}
        // visibleConformation={visibleConformation}
        ShowJson={ShowJson}
      />
       <div className="flex justify-center items-center mt-6 space-x-4">
            <button
              onClick={prevPage}
              disabled={page === 1}
              className={`px-5 py-2 rounded-lg text-white font-semibold transition-all duration-300 ${page === 1 ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600 active:scale-95"
                }`}
            >
              ⬅ Previous
            </button>
            <span className="text-lg font-semibold text-gray-700">
              Page <span className="text-green-600">{page}</span> of <span className="text-green-600">{totalPages}</span>
            </span>

            <button
              onClick={nextPage}
              disabled={page === totalPages}
              className={`px-5 py-2 rounded-lg text-white font-semibold transition-all duration-300 ${page === totalPages ? "bg-gray-400 cursor-not-allowed" : "bg-green-500 hover:bg-green-600 active:scale-95"
                }`}
            >
              Next ➡
            </button>
          </div>
      

      {
        visibleConformation &&
        <div className="mt-12 bg-white  ml-2 mr-4 ">
            <svg
          onClick={() => setVisibilityConformation(!visibleConformation)}
          className="w-6 h-6 cursor-pointer "
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>

          {
            JSON.stringify(data)
          }


        </div>
      }
    </Layout>
  );
};

export default Transaction;
