import http from './http';
import {API_BASE_URL} from '../config';

export default class TransationService {
  static getTransaction = () =>
    http
      .get(`${API_BASE_URL}/transaction`)
      .then((res) => res.data);


}
