import http from "../http";
import { API_BASE_URL } from "../../config";

export default class LocationService {
  static getLocations({ type, division, district }) {
    let params = {};
    if (type) params.type = type;
    if (division) params.division = division;
    if (district) params.district = district;
    const config = {
      params,
    };
    return http
      .get(`${API_BASE_URL}/locations`, config)
      .then((response) => response.data);
  }

  static getDivisions() {
    return http
      .get(`${API_BASE_URL}/divisions`)
      .then((response) => response.data);
  }

  static addLocation(payload) {
    return http
      .post(`${API_BASE_URL}/locations`, payload)
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  }

  static updateLocation(id, payload) {
    return http
      .put(`${API_BASE_URL}/locations/${id}`, payload)
      .then((response) => response.data);
  }

  static deleteLocation(id) {
    return http
      .delete(`${API_BASE_URL}/locations/delete/${id}`)
      .then((response) => response.data);
  }
  static activeStatus(id) {
    return http
      .put(`${API_BASE_URL}/locations/status/${id}`)
      .then((response) => response.data);
  }

  static addSubLocation(id, payload) {
    return http
      .put(`${API_BASE_URL}/locations/sub-district/${id}`, payload)
      .then((response) => response.data);
  }
  static updateSubLocation(id, payload) {
    return http
      .put(`${API_BASE_URL}/locations/sub-district/${id}`, payload)
      .then((response) => response.data);
  }

  //  TODO api for route

  static getRoutes(params) {
    const config = {
      params,
    };
    return http
      .get(`${API_BASE_URL}/busRoute`, config)
      .then((response) => response.data);
  }

  static addLocationToRoute(routeId, locationId) {
    return http
      .put(`${API_BASE_URL}/busRoute/${routeId}/${locationId}`)
      .then((response) => response.data);
  }
  static deleteLocationFromRoute(routeId, locationId) {
    return http
      .delete(`${API_BASE_URL}/busRoute/${routeId}/${locationId}`)
      .then((response) => response.data);
  }

  static addRoute(name) {
    const payload = {
      name: name,
    };
    return http
      .post(`${API_BASE_URL}/busRoute`, payload)
      .then((response) => response.data);
  }
}
