import React, { useState, useEffect, useContext, useRef } from "react";
import { Redirect } from "react-router-dom";
import SupportService from "../../services/SupportService";
import AuthContext from "../../shared/AuthContext";
import ChatCard from "./chatCard";

function SupportItem(props) {
  const userProfile = useContext(AuthContext);
  const [chatHistory, setChatHistory] = useState([]);
  const [ticket, setTicket] = useState(props.location.state);
  const [msg, setMsg] = useState("");
  const [backToPrev, setBackToPrev] = useState(false);
  const [changeStatus, setChangeStatus] = useState([]);
  const chatEndRef = useRef(null);

  const fetchHistory = async () => {
    try {
      const receivedMessage = await SupportService.getSupportChatMessage(ticket.data._id);
      setChatHistory(receivedMessage.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchHistory();
    changeTicketStatus();
  }, [ticket]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  const changeTicketStatus = () => {
    const statusMap = {
      Initiated: ["InReview", "Resolved"],
      InReview: ["Initiated", "Resolved"],
      Resolved: ["Initiated", "InReview"],
    };
    setChangeStatus(statusMap[ticket.data.status] || []);
  };

  const onSend = async () => {
    if (!msg.trim()) return;
    try {
      const payload = {
        text: msg,
        _id: Math.floor(Math.random() * 1000000).toString(),
        user: {
          _id: userProfile.user.user._id,
          type: "admin",
          name: userProfile.user.user.name,
          avatar: userProfile.user.user.profile,
        },
      };
      await SupportService.sendSupportChatMessage(ticket.data._id, payload);
      setMsg("");
      fetchHistory();
    } catch (error) {
      console.error("Error while sending message:", error);
    }
  };

  const updateTicket = async (props) => {
    const payload = props?.status
      ? { status: props.status }
      : { supportAssignedToRole: ticket.data.supportAssignedToRole === "Support" ? "Admin" : "Support" };
    try {
      const response = await SupportService.updateTicket(ticket.data._id, payload);
      setTicket({ data: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  if (backToPrev) {
    return <Redirect to={{ pathname: "/support" }} />;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-50">
      <div className="bg-blue-600 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Title: {ticket.data.title}</h1>
        <div className="flex items-center space-x-4">
          <div className="flex items-center">
            <span className="mr-2">Status:</span>
            <select
              value={ticket.data.status}
              onChange={(e) => updateTicket({ status: e.target.value })}
              className="bg-blue-500 text-white px-2 py-1 rounded text-sm hover:bg-blue-400 transition-colors duration-200"
            >
              <option value="Initiated">Initiated</option>
              <option value="InReview">InReview</option>
              <option value="Resolved">Resolved</option>
            </select>
          </div>
          <div className="flex items-center">
            <span className="mr-2">Assigned:</span>
            <select
              value={ticket.data.supportAssignedToRole}
              onChange={() => updateTicket()}
              className="bg-blue-500 text-white px-2 py-1 rounded text-sm hover:bg-blue-400 transition-colors duration-200"
            >
              <option value="Admin">Admin</option>
              <option value="Support">Support</option>
            </select>
          </div>
          <button
            onClick={() => setBackToPrev(true)}
            className="flex items-center bg-white text-blue-600 px-4 py-2 rounded-full shadow-md hover:bg-blue-50 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
            Back
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {chatHistory.map((item, index) => (
          <ChatCard key={index} item={item} />
        ))}
        <div ref={chatEndRef} />
      </div>

      {ticket.data.status !== "Resolved" && (
        <div className="p-4 bg-white border-t">
          <div className="flex items-end space-x-2">
            <div className="flex-1 relative">
              <textarea
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                onKeyDown={(e) => {
                  if ((e.key === 'Enter' && e.shiftKey) || (e.key === 'Enter' && (e.metaKey || e.ctrlKey))) {
                    e.preventDefault();
                    onSend();
                  }
                }}
                className="w-full p-3 pr-12 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none bg-gray-50 transition-all duration-200 ease-in-out"
                placeholder="Type your message here..."
                rows="3"
              />
              <span className="absolute right-3 bottom-3 text-xs text-gray-400">
                Shift + Enter or {navigator.platform.indexOf('Mac') !== -1 ? 'Cmd' : 'Ctrl'} + Enter to send
              </span>
            </div>
            <button
              onClick={onSend}
              disabled={!msg.trim()}
              className={`px-6 py-3 rounded-lg flex items-center transition-all duration-200 ${msg.trim()
                ? 'bg-blue-500 text-white hover:bg-blue-600 shadow-md hover:shadow-lg'
                : 'bg-gray-200 text-gray-400 cursor-not-allowed'
                }`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
              </svg>
              Send
              <span className="ml-2 text-xs opacity-75">(⌘↵)</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SupportItem;