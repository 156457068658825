import React, { useState, useEffect } from "react";
import moment from "moment";

const Popup = ({ isOpen, onClose, detailsInformation }) => {
  const [sectionData, setSectionData] = useState(null);

  useEffect(() => {
    fetchSectionData();
  }, []);

  const fetchSectionData = () => {
    const fetchedData = {
      id: detailsInformation._id,
      tripId: detailsInformation.transactionFor.trip._id,
      fromName: detailsInformation.transactionFor.trip.fromLocation.name,
      fromDivision: detailsInformation.transactionFor.trip.fromLocation.division,
      fromDistrict: detailsInformation.transactionFor.trip.fromLocation.district,
      FromNote: detailsInformation.transactionFor.trip.fromLocation.note,
      toName: detailsInformation.transactionFor.trip.toLocation.name,
      toDivision: detailsInformation.transactionFor.trip.toLocation.division,
      toDistrict: detailsInformation.transactionFor.trip.toLocation.district,
      toNote: detailsInformation.transactionFor.trip.toLocation.note,
      fare: detailsInformation.fee,
      tripType: detailsInformation.transactionFor.trip.tripType,
      vehicleType: detailsInformation.transactionFor.trip.vehicleType,
      startDate: detailsInformation.transactionFor.trip.startDate,
      startTime: detailsInformation.transactionFor.trip.startTime,
      rawResponse: detailsInformation.rawResponse,
      // paymentMethod: "Credit Card",
    };
    setSectionData(fetchedData);
  };

  const TransactionItem = ({ item }) => {
    const [key, ...valueParts] = item.split(':');
    const value = valueParts.join(':').trim();

    return (
      <div className="flex flex-col sm:flex-row sm:items-start mb-2">
        <span className="font-semibold mr-2 min-w-[120px] mb-1 sm:mb-0">{key}:</span>
        <span className="text-gray-700 break-all">{value}</span>
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white p-8 rounded-lg shadow-lg z-10 max-w-xl w-full overflow-y-auto overflow-x-auto max-h-screen-80">
            <div className="flex justify-between items-center py-4">
              <h2 className="text-xl font-semibold text-gray-800">Transaction Details</h2>
              <button
                onClick={onClose}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-150 ease-in-out"
              >
                <svg className="h-5 w-5 mr-1.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
                Close
              </button>
            </div>
            {sectionData && (<p className="bg-gray-100 rounded-lg p-3">Transaction ID: {sectionData.id}</p>)}
            <div className="h-4"></div>
            {sectionData && (<p className="bg-gray-100 rounded-lg p-3">Trip ID: {sectionData.tripId}</p>)}
            {sectionData && (
              <div className="mt-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h3 className="text-md font-semibold mb-2">From:</h3>
                    <div className="mb-4">
                      <InfoItem label="Name" value={sectionData.fromName} />
                      <InfoItem
                        label="Division"
                        value={sectionData.fromDivision}
                      />
                      <InfoItem
                        label="District"
                        value={sectionData.fromDistrict}
                      />
                      <InfoItem label="Note" value={sectionData.FromNote} />
                    </div>
                  </div>
                  <div>
                    <h3 className="text-md font-semibold mb-2">To:</h3>
                    <div className="mb-4">
                      <InfoItem label="Name" value={sectionData.toName} />
                      <InfoItem
                        label="Division"
                        value={sectionData.toDivision}
                      />
                      <InfoItem
                        label="District"
                        value={sectionData.toDistrict}
                      />
                      <InfoItem label="Note" value={sectionData.toNote} />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <h3 className="text-md font-semibold mb-2">
                    Additional Information:
                  </h3>
                  <div className="mb-4">
                    <InfoItem label="Fare" value={sectionData.fare} />
                    <InfoItem label="Trip Type" value={sectionData.tripType} />
                    <InfoItem
                      label="Vehicle Type"
                      value={sectionData.vehicleType}
                    />
                    <InfoItem
                      label="Start Date"
                      value={moment(sectionData.startDate).format('DD MMM YYYY')}
                    />
                    <InfoItem
                      label="Start Time"
                      value={sectionData.startTime}
                    />
                    {/* <InfoItem
                      label="Payment Method"
                      value={sectionData.paymentMethod}
                    /> */}
                  </div>
                </div>
                {
                  sectionData?.rawResponse && (
                    <div className="mt-4">
                      <h3 className="text-md font-semibold mb-2">
                        bKash Response
                      </h3>
                      <div className="mb-4 overflow-x-auto">
                        <h3 className="text-md mb-2">{JSON.stringify(sectionData?.rawResponse).split(',').map((item, index) => (
                          // <div key={index} className="flex items-center">
                          //   <span className="font-semibold mr-2">{item.split(':')[0]}:</span>
                          //   <span className="text-gray-700">{item.split(':')[1]}</span>
                          // </div>
                          <TransactionItem key={index} item={item} />
                        ))}</h3>
                      </div>
                    </div>
                  )
                }

              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const InfoItem = ({ label, value }) => (
  <div className="flex items-center">
    <span className="font-semibold mr-2">{label}:</span>
    <span className="text-gray-700">{value}</span>
  </div>
);

export default Popup;
