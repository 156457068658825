// import http from '../utils/http';
import http from './http';
import { API_BASE_URL } from '../config';

export default class TripService {
  static getTrips = params => {
    const config = {
      params,
    };
    return http.get(`${API_BASE_URL}/trips`, config).then(res => res);
  };

  static getTrip = tripId =>
    http.get(`${API_BASE_URL}/trip/${tripId}`).then(res => res);

  static getTripByDriver = driverId =>
    http.get(`${API_BASE_URL}/trips/driver/${driverId}`).then(res => res);

  static createTripByDriver = payload => {
    const tripId = payload?.tripId;
    return http
      .post(`${API_BASE_URL}/trips/${tripId}/bids`, payload)
      .then(res => res);
  };

  static startTripByDriver = payload => {
    const tripId = payload?.tripId;
    const driverId = payload?.driverId;
    return http
      .put(`${API_BASE_URL}/trips/driver/${driverId}/${tripId}/start`, payload)
      .then(res => res);
  };
  static cancelTripByDriver = payload => {
    const tripId = payload?.tripId;
    const driverId = payload?.driverId;
    return http
      .put(`${API_BASE_URL}/trips/driver/${driverId}/${tripId}/cancel`, payload)
      .then(res => res);
  };
  static completeTripByDriver = payload => {
    const tripId = payload?.tripId;
    const driverId = payload?.driverId;
    return http
      .put(
        `${API_BASE_URL}/trips/driver/${driverId}/${tripId}/completed`,
        payload,
      )
      .then(res => res);
  };
  static getBidDetails = (bidId) =>
    http.get(
      `${API_BASE_URL}/bids/${bidId}`);

  static refundToBkash = (transactionId) =>
    http.post(
      `${API_BASE_URL}/transaction/${transactionId}/refund`);
}
