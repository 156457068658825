import http from '../http';
import {API_BASE_URL} from '../../config';

export default class VehicleService {
  static getOwnerVehicles = (ownerId) =>
    http
      .get(`${API_BASE_URL}/owners/${ownerId}/vehicles`)
      .then((res) => res.data);
  static getVehicleById = (vehicleId) =>
    http.get(`${API_BASE_URL}/vehicles/${vehicleId}`).then((res) => res.data);

  static addVehicles = (payload) =>
    http.post(`${API_BASE_URL}/vehicles`, payload);

  static updateVehicles = (vehicleId, payload) =>
    http.put(`${API_BASE_URL}/vehicles/${vehicleId}`, payload);

  static updateVehicleDocument = (vehicleId, payload) =>
    http.put(`${API_BASE_URL}/vehicles/document/${vehicleId}`, payload);
    
    static verifyVehicle = (vehicleId, payload) =>
    {
      return http.put(`${API_BASE_URL}/vehicles/${vehicleId}/verify`, payload);
    }
}
