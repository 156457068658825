import React from "react"
import Layout from "../../components/Layout/index"
import Location from "../../components/Tables/location_table"
import {Fragment, useState, useEffect} from 'react'
import AdminService from '../../services/admin/AdminService';
import LocationService from '../../services/owner/LocationService';
import "./index.css"
import LocationAdd from "./LocationAdd"
import RouteAdd from "./RouteAdd"
import UpdareSubDistrict from "./update_sub_district"
import {sideBarItems} from '../../utils';
import _ from 'lodash';

const BusRoute = () => {
  const [locations, setLocations] = useState([]);
  const [filteredLocation, setFilteredLocation] = useState([]);
  const [busRoutes, setBusRoutes] = useState([]);
  const [filteredBusRoutes, setFilteredBusRoutes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedRoute, setSelectedRoute] = useState();
  const [showUpdatePanel, setUpdatePanelStatus] = useState(false);
  const [showAddPanel, setAddPanelStatus] = useState(false);

  useEffect(() => {
    fetchLocation();
    fetchRoute();
  }, [])

  const fetchLocation = () => {
    LocationService.getLocations({}).then(locations => {
      setLocations(locations);
      setFilteredLocation(locations);
    })
  }

  const fetchRoute = () => {
    LocationService.getRoutes({}).then(busRoutes => {
      setBusRoutes(busRoutes);
      setFilteredBusRoutes(busRoutes);
    })
  }

  const setUpdateLocation = (location) => {
    setSelectedLocation(location);
    setUpdatePanelStatus(true)
    setAddPanelStatus(false)
  }

  const addLocationToRoute = (item) => {
    LocationService.addLocationToRoute(selectedRoute._id, item._id).then(location => {
      fetchRoute();
      setSelectedRoute(null)
    })
  }

  const deleteLocationFromRoute = (roteId,item) => {
    LocationService.deleteLocationFromRoute(roteId, item.placeId).then(location => {
      fetchRoute();
    })
  }

  const selectRoute = (item) => {
    setSelectedRoute(item)
  }

  const handleChangeSearch = (searchQuery) => {
    let filteredBusRoutes = _.filter(busRoutes, item => {
      return item?.name?.match(new RegExp(searchQuery, 'gi'))
    });
    setFilteredBusRoutes(filteredBusRoutes)
  }


  const BusRoureItem = ({item}) => {
    const containerCss = selectedRoute?.name === item?.name ?
      "bg-white shadow sm:rounded-lg my-2 border-2 border-indigo-300" :
      "bg-white shadow sm:rounded-lg my-2";
    return (
      <div className={containerCss}>
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{item.name}</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Covering District</p>
          </div>
          <div className="mt-5 ">
            {item.places.map((place) => {
              return (
                <span
                  className="inline-flex rounded-full items-center py-1 pl-2.5 pr-1 mx-2 my-2 text-sm font-medium bg-indigo-100 text-indigo-700">
                  {place.name}
                  <button
                    onClick={()=>deleteLocationFromRoute(item._id,place)}
                    type="button"
                    className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white">
                    <span className="sr-only">Remove large option</span>
                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                    </svg>
                  </button>
                </span>
              )
            })}
            <button
              onClick={() => {
                setSelectedRoute(selectedRoute ? null : item);
              }}
              type="button"
              className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
    )
  }

  const LocationAddPanel = () => {
    return (
      <div>
        <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
          <div className="max-w-lg w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">Search</label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                     fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"/>
                </svg>
              </div>
              <input id="search" name="search"
                     className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                     placeholder="Search" type="search"/>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const RouteHeader = ()=>{
    return(
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Bus route
          </h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Add new bus route which big route like dhaka to sylhet
            </p>
          </div>
          <div className="mt-5">
            <button
              onClick={()=> setAddPanelStatus(true)}
              type="button"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm">
              Add new route
            </button>
          </div>
        </div>
      </div>
    )
  }

  const addRoute = (name)=>{
    if(name){
      LocationService.addRoute(name).then(data=>{
        fetchRoute();
        setAddPanelStatus(false)
      })
    }

  }

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.location.key}>
      <div className="flex w-full  overflow-y-scroll">
        <div className=" w-full  mr-10 mt-8 ">
          <RouteHeader/>
          {filteredBusRoutes.map((item) => {
            return (<BusRoureItem item={item}/>)
          })}
        </div>
        {selectedRoute &&
        <LocationAdd
          addLocationToRoute={addLocationToRoute}
          locations={locations}
          setAddPanelStatus={()=>setSelectedRoute(null)}
          />
        }
        {showAddPanel &&
        <RouteAdd
          addRoute={addRoute}
          setAddPanelStatus={()=>setAddPanelStatus(false)}
          showAddPanel={showAddPanel}/>
        }
      </div>

    </Layout>
  )
};

export default BusRoute;
