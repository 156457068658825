import React from "react";

const CityList = ({ locations, saveFromCity, saveToCity }) => {
  return (
    <div className="py-2 h-screen overflow-y-auto px-4 sm:px-6 lg:px-8">
      {locations.map((item, index) => (
        <div 
          key={index} 
          className="bg-blue-100 rounded-lg shadow-md mb-4 p-4 transition-all hover:bg-blue-200"
        >
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
            <div className="flex-1 mb-3 sm:mb-0">
              <p className="text-gray-800 font-semibold break-words">
                {item?.path}
              </p>
            </div>
            <div className="flex flex-wrap gap-2 w-full sm:w-auto">
              <button
                onClick={() => saveFromCity(item)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 flex-grow sm:flex-grow-0"
              >
                From
              </button>
              <button
                onClick={() => saveToCity(item)}
                className="bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 flex-grow sm:flex-grow-0"
              >
                To
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CityList;