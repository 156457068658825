import http from '../http';
import {API_BASE_URL} from '../../config';


export default class AdminService {
  static loginOwner(payload) {
    return http.post(`${API_BASE_URL}/auth/owner/login`, payload);
  }

  static getOwners() {
    const config = {
      params:{
        isOwner:true
      }
    }
    return http
      .get(`${API_BASE_URL}/owners`,config)
      .then((response) => response);
  }

  static getDrivers() {
    const config = {
      params:{
        isDriver:true
      }
    }
    return http
      .get(`${API_BASE_URL}/owners`,config)
      .then((response) => response);
  }

  static getOwnerProfile(ownerId) {
    return http
      .get(`${API_BASE_URL}/owners/${ownerId}`)
      .then((response) => response.data);
  }
  static logout() {
    return http
      .get(`${API_BASE_URL}/auth/owner/logout`)
      .then((response) => response);
  }

  static forgotPassword(type, payload) {
    return http.post(`${API_BASE_URL}/forgot-password/${type}`, payload);
  }

  static resetOwnerPassword(token, payload) {
    return http.post(`${API_BASE_URL}/owners/reset-password/${token}`, payload);
  }

  static resetPasswordByOTP(token, payload) {
    return http.post(`${API_BASE_URL}/reset-password/mobile`, payload);
  }

  static signUp(payload) {
    return http.post(`${API_BASE_URL}/auth/owner/sign-up`, payload);
  }
  static updateProfile(id, payload) {
    return http
      .put(`${API_BASE_URL}/owners/${id}`, payload)
      .then((response) => response);
  }

  static verifyOTP(userId, payload) {
    return http.put(`${API_BASE_URL}/auth/owner/verify-otp/${userId}`, payload);
  }
  static verifyOTPWhenForgetPassword(payload) {
    return http.post(
      `${API_BASE_URL}/owners/forgot-password/otp/verify`,
      payload,
    );
  }

  static verifySocialAccount(payload, type) {
    console.log(type, payload);
    return http.post(`${API_BASE_URL}/auth/${type}/callback/mobile`, payload);
  }

  static getCurrentUser() {
    return http
      .get(`${API_BASE_URL}/api/v2/current-user`)
      .then((response) => response.data);
  }

  static updateFirebaseToken(payload) {
    return http
      .put(`${API_BASE_URL}/users/updateFirebaseToken`, payload)
      .then((response) => response.data);
  }

  static uploadProfilePicture = (userId, payload) => {
    return http
      .post(`${API_BASE_URL}/users/${userId}/picture`, payload)
      .then((res) => res.data);
  };

  static uploadImage = (payload) => {
    return http
      .post(`${API_BASE_URL}/upload/private/s3`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log('upload', err);
      });
  };
  static updateMobile = (userId, payload) =>
    http.put(`${API_BASE_URL}/users/mobile/${userId}`, payload);

  static removeMobile(user) {
    return http.put(`${API_BASE_URL}/users/remove-mobile/${user._id}`, user);
  }

  static isRegistered = () =>
    http.get(`${API_BASE_URL}/scholarship/registered`).then((res) => res.data);

  static isExistUser = (mobile) =>
    http.get(`${API_BASE_URL}/owners/exists/${mobile}`).then((res) => res.data);
    
static isExistSession = () =>
    http.get(`${API_BASE_URL}/auth/check/session`).then((res) => res.data);

  // TODO  location api
  static getLocations() {
    return http
        .get(`${API_BASE_URL}/locations?type=subDistrict`)
        .then((response) => response.data);
  }
//  TODO fare api
  static addFare(payload) {
    return http.post(`${API_BASE_URL}/fare`, payload);
  }

  static getFareChart(params) {
    const config = {
      params
    }
    return http.get(`${API_BASE_URL}/fare`,config);
  }

  static updateFare(fareId,payload) {
    return http.put(`${API_BASE_URL}/fare/${fareId}`, payload);
  }

  static dashBoardData() {
    return http.get(`${API_BASE_URL}/dashboardData`);
  }

  static bids(params){
    return http.get(`${API_BASE_URL}/trips/${params}/bids`);
  }

}
