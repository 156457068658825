import Header from "./Header";
import Landing from "./Landing";
import Introduction from "./Introduction";
import AboutPartner from "./AboutPartner";
import Footer from './Footer';

export {
  Header,
  Landing,
  Introduction,
  AboutPartner,
  Footer,

}
