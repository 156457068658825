import React from "react"

const DriverInfo=()=>{
    return(
        <div>
            <div>
                <div className="dashboard_profile  pt-6">
                    <img className="  h-24 w-24  rounded-full" src="https://scontent.fcgp17-1.fna.fbcdn.net/v/t1.6435-9/79776706_1077879655892660_6715394724091396096_n.jpg?_nc_cat=103&ccb=1-3&_nc_sid=174925&_nc_ohc=IAb23VM4F7sAX9R5RNa&_nc_ht=scontent.fcgp17-1.fna&oh=26ac73c356fafa137451b77fc20a1697&oe=60D5C384"/>

                </div>
                <div className="mt-12">
                    <tr>
                        <td className="px-6 ">
                            <div className="text-sm font-medium text-gray-900">Name :</div>
                        </td>
                        <td>
                            <div className="-ml-5 text-sm font-medium text-gray-900">karim hassan</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6 ">
                            <div className="text-sm font-medium text-gray-900">Phone :</div>
                        </td>
                        <td>
                            <div className="-ml-5 text-sm font-medium text-gray-900">017162636633</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6">
                            <div className=" text-sm font-medium text-gray-900">City :</div>
                        </td>
                        <td >
                            <div className="-ml-5 text-sm font-medium text-gray-900">dhaka</div>
                        </td>
                    </tr>
                    <tr>
                        <td className="px-6">
                            <div className="text-sm font-medium text-gray-900">Status :</div>
                        </td>
                        <td>
                            <div className=" -ml-5 text-sm font-medium text-gray-900">Not verify</div>
                        </td>
                    </tr>

                    <button className="items-center justifu-center varify-button">Varify Driver</button>

                </div>

            </div>

            <h3 className="px-6 mt-12  my-2 text-sm font-medium text-gray-900" >Assigned Car info</h3>

            <div className=" p-1 ml-6 bg-indigo-100 w-3/5">

                <tr>
                    <td className="px-6 ">
                        <div className="text-sm font-medium text-gray-900">Name :</div>
                    </td>
                    <td>
                        <div className="-ml-5 text-sm font-medium text-gray-900">Toyto</div>
                    </td>
                </tr>
                <tr>
                    <td className="px-6">
                        <div className=" text-sm font-medium text-gray-900">Car no :</div>
                    </td>
                    <td >
                        <div className="-ml-5 text-sm font-medium text-gray-900">Dha-metro-202993</div>
                    </td>
                </tr>
                <tr>
                    <td className="px-6">
                        <div className="text-sm font-medium text-gray-900">Owner :</div>
                    </td>
                    <td>
                        <div className=" -ml-5 text-sm font-medium text-gray-900">Ramzan</div>
                    </td>
                </tr>
                <tr>
                    <td className="px-6">
                        <div className="text-sm font-medium text-gray-900">Seat :</div>
                    </td>
                    <td>
                        <div className=" -ml-5 text-sm font-medium text-gray-900">Max 7 seats</div>
                    </td>
                </tr>



            </div>

        </div>

    )
};

export default DriverInfo;