import React from 'react';
import Modal from 'react-modal';
import close from '../../assets/close_icon.svg';

export default function OwnerItem({owner}) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [car, setCar] = React.useState();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  const CarModal = () => {
    if (car)
      return (
        <Modal
          isOpen={modalIsOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
           // style={customStyles}
          contentLabel="Example Modal"
        >
          <div
            className="flex flex-row justify-between p-2 bg-white  rounded-tl-lg rounded-tr-lg"
          >
            <p className="flex"></p>
            <img onClick={closeModal} width={30} height={30} src={close}/>
          </div>

          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Name :</dt>
            {' '}
            <dd className="inline text-black">{car?.vehicle?.name}</dd>
          </div>
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Model :</dt>
            {' '}
            <dd className="inline text-black">{car?.vehicle?.model}</dd>
          </div>
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Type :</dt>
            {' '}
            <dd className="inline text-black">{car?.vehicle?.type} , {car?.vehicle?.seat} seat</dd>
          </div>
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Registration :</dt>
            {' '}
            <dd className="inline text-black">{car?.vehicle?.registration?.number}</dd>
          </div>
          <div>
            <h2>Car photos:</h2>
            <div className='flex flex-row flex-wrap'>
              {car?.vehicle?.photos?.map((item,index)=>{
                return(
                  <img className={'p-4'} width="200" height="200" src={car?.vehicle?.photos[index]?.uri}/>
                )
              })}
            </div>
          </div>

          <div>
            <h2>Registration photos:</h2>
            <div className='flex flex-row flex-wrap'>
              {car?.vehicle?.registration?.card?.map((item,index)=>{
                return(
                  <img className={'p-4'} width="200" height="200" src={car?.vehicle?.registration?.card[index]?.uri}/>
                )
              })}
            </div>
          </div>

          <div>
            <h2>Insurance photos:</h2>
            <div className='flex flex-row flex-wrap'>
              {car?.vehicle?.insurance?.map((item,index)=>{
                return(
                  <img className={'p-4'} width="200" height="200" src={car?.vehicle?.insurance[index]?.uri}/>
                )
              })}
            </div>
          </div>
          <div>
            <h2>Tex token photos:</h2>
            <div className='flex flex-row flex-wrap'>
              {car?.vehicle?.token?.map((item,index)=>{
                return(
                  <img className={'p-4'} width="200" height="200" src={car?.vehicle?.token[index]?.uri}/>
                )
              })}
            </div>
          </div>

        </Modal>
      )
    else return null;
  }
  return (
    <article className="p-4 flex space-x-4 flex-col">
      {/*{JSON.stringify(owner)}*/}
      {/*<img src={recipe.image} alt="" className="flex-none w-18 h-18 rounded-lg object-cover bg-gray-100" width="144" height="144" />*/}
      <div className="min-w-0 relative flex-auto sm:pr-20 lg:pr-0 xl:pr-20 mx-6">
        <h2 className="text-lg font-semibold text-black mb-0.5">
          {owner.firstName} {owner.lastName}
        </h2>
        <dl className="flex flex-wrap text-sm font-medium whitespace-pre">
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Mobile :</dt>
            {' '}
            <dd className="inline text-black">{owner?.mobile}</dd>
          </div>
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Nid :</dt>
            {' '}
            <dd className="inline text-black">{owner?.nid}</dd>
          </div>
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Address :</dt>
            {' '}
            <dd className="inline text-black">{owner?.address?.subDistrict} / {owner?.address?.city}</dd>
          </div>
          <div className="flex-none w-full mt-0.5 font-normal">
            <dt className="inline">Status :</dt>
            {' '}
            <dd className="inline text-black">{owner?.isVerified ? 'Verified' : 'Not Verified'}</dd>
          </div>
        </dl>
      </div>
      <div className={'flex flex-row flex-wrap'}>
        {owner.vehicles.map(item => {
          return (
            <div onClick={() => {
              openModal();
              setCar(item);
            }} className={'bg-gray-200 w-200 p-4 m-2'}>
              <img className={'w-200 h-200'} width="200" height="200" src={item?.vehicle?.photos[0]?.uri}/>
              <p>{item?.vehicle?.name}</p>
              <p>{item?.vehicle?.model}</p>
            </div>
          )
        })}
      </div>
      <CarModal/>
    </article>
  )
}
