import React from "react";
import Layout from "../Layout/index";
import { useState, useEffect } from "react";
import AdminService from "../../services/admin/AdminService";
import DriverTable from "../Tables/driver_table";
import DriverInfo from "../Tables/driver_manage_info";
import Conformation from "../common/conformation";

import DriverInfomation from "./driverInfo";
import CarInfoModel from "../common/car_info_model";
import { sideBarItems } from "../../utils";
import _ from "lodash";
import TripService from "../../services/TripService";
import DriverBidInfo from '../../components/common/driver_bid_model'

const DriverList = () => {
  const [owners, setOwners] = useState([]);
  const [filteredOwners, setFilteredOwners] = useState([]);
  const [showdriveinfo, setshowdriveinfo] = useState(false);
  const [driveinfo, setDriveinfo] = useState();
  const [isedit, setIsedit] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);


  const [selectedTrip, setSelectedTrip] = useState();
  const [individualBId, setIndividualBId] = useState([]);



  const ShowDriveSecthom = (item) => {
    setshowdriveinfo(true);
    setDriveinfo(item);
  };

  useEffect(() => {
    initApi();
  }, []);

  const individualBIdApi = async () => {
    // your need to pass driver id  then call
    const res = await TripService.getTripByDriver();
    setIndividualBId((_.isArray(res?.data) && res?.data) || []);
  };

  const initApi = () => {
    AdminService.getDrivers().then((data) => {
      setOwners(data.data);
      setFilteredOwners(data.data);
    });
  };

  const handleChangeSearch = (searchQuery) => {
    let filteredOwners = _.filter(owners, (item) => {
      return (
        item?.mobile?.match(new RegExp(searchQuery, "gi")) ||
        item?._id?.match(new RegExp(searchQuery, "gi")) ||
        // item?.firstName?.match(new RegExp(searchQuery, "gi")) ||
        item?.myOwners[0]?.name?.match(new RegExp(searchQuery, "gi")) ||
        // item?.lastName?.match(new RegExp(searchQuery, "gi"))
        item?.name?.match(new RegExp(searchQuery, "gi"))
        
      );
    });
    setFilteredOwners(filteredOwners);
  };

  const handleModal = (isVisible) => {
    setVisibleModal(isVisible)
  }

  return (
    <Layout
      handleChangeSearch={handleChangeSearch}
      title={sideBarItems.driver.key}
      modal={
        <DriverBidInfo
          handleModal={handleModal}
          driveinfo={driveinfo}

          // carInfo={carInfo}
        />
      }

      visibleModal={visibleModal}
      handleModal={handleModal}
    >
      {/*<Conformation/>*/}
      <div className="flex w-full ">
        <div className="-ml-8 w-full ">
          <DriverTable
            tableFor='driver'
            owners={filteredOwners}
            isedit={isedit}
            ShowDriveSecthom={ShowDriveSecthom}
            handleModal ={(isVisible,item)=>{
              setDriveinfo(item);
              handleModal(isVisible)
            }}
          />
        </div>
        {showdriveinfo && (
          <DriverInfomation
            driveinfo={driveinfo}
            setshowdriveinfo={setshowdriveinfo}
            refresh={initApi}
            showdriveinfo={showdriveinfo}
            isDriver={true}
          />
        )}
      </div>
    </Layout>
  );
};
export default DriverList;
